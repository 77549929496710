import {
  type Dispatch,
  type ReactElement,
  type ReactNode,
  type SetStateAction,
  useEffect,
  useState
} from 'react';

import {
  MenuItem,
  Select,
  CircularProgress,
  type SelectProps
} from '@mui/material';

import { KeyboardArrowDown } from '@mui/icons-material';

import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router';
import { ExposeService } from 'services/ExposeService';
import type { WorkspaceListResponse } from './interfaces/interfaces';
import { HOME_VIEW_TYPES, NAVIGATOR_NAMES } from './interfaces/enums';
import {
  USER_TRACKING_LOCATION_NAMES,
  userTrackingLocation
} from 'atoms/atomUserLocation';
import { homeViewRenderAtom } from 'home/atoms/atomActivedChat';
import { navbarActiveTab } from 'home/atoms/atomNavbarActiveTab';

import 'common/Common.scss';

export interface valueOfSelectedFetch {
  id: string;
  name: string;
}

interface scrollSelectProps {
  localAmount?: number;
  valueController: [
    valueOfSelectedFetch | undefined,
    Dispatch<SetStateAction<valueOfSelectedFetch | undefined>>
  ];
}

const defaultValue = 'Select one';

const SelectScrollFetch = ({
  localAmount = 10,
  valueController,
  ...restPops
}: scrollSelectProps & SelectProps): ReactElement => {
  const [value, setValue] = valueController;
  const [navbarActiveTabIndex, setNavbarActiveTabIndex] =
    useRecoilState(navbarActiveTab);

  const navigate = useNavigate();
  const exposeService = ExposeService.getInstance();

  const [userLocationVariable, setUserLocationVariable] =
    useRecoilState(userTrackingLocation);
  const [viewRender, setViewRender] = useRecoilState(homeViewRenderAtom);

  const [displayOptions, setDisplayOptions] = useState<
    WorkspaceListResponse | undefined
  >();

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [openSelect, setOpenSelect] = useState(false);

  const valueIsUndefined = value === undefined;
  const disableSelect = displayOptions === undefined;

  useEffect(() => {
    if (displayOptions === undefined) {
      handleRequests(1, localAmount);
    }
  }, [navbarActiveTabIndex]);

  const handleRequests = (page: number, amount: number): void => {
    exposeService
      .getUserWorkspace(page, amount)
      .then((response) => {
        if (response.data !== undefined) {
          setPage(page + 1);
          setLoading(false);
          setDisplayOptions((prevOptions) => {
            const newData =
              prevOptions !== undefined
                ? [...prevOptions.data, ...response.data]
                : response.data;
            return {
              data: newData,
              collectionSize:
                response.collectionSize ?? prevOptions?.collectionSize
            };
          });
        }
      })
      .catch(() => {
        setLoading(false);
        throw new Error('Failed to fetch options');
      });
  };

  const executeNewSelection = (id: string, name: string): void => {
    if (viewRender.type !== HOME_VIEW_TYPES.WORKSPACES) {
      setViewRender({
        type: HOME_VIEW_TYPES.WORKSPACES,
        stored: { render: viewRender, userLocationVariable }
      });
    }
    if (
      USER_TRACKING_LOCATION_NAMES.WORKSPACES !== userLocationVariable.current
    ) {
      setUserLocationVariable({
        ...userLocationVariable,
        current: USER_TRACKING_LOCATION_NAMES.WORKSPACES,
        previous: userLocationVariable.current
      });
    }
    setValue({ id, name });
    if (navbarActiveTabIndex !== 4) {
      setNavbarActiveTabIndex(4);
    }
    void navigate(`/${NAVIGATOR_NAMES.WORKSPACES}/${id}`);
  };

  const removeSelection = (): void => {
    if (
      viewRender.type === HOME_VIEW_TYPES.WORKSPACES ||
      viewRender.type === HOME_VIEW_TYPES.WIZARD
    ) {
      setViewRender({ ...viewRender, type: HOME_VIEW_TYPES.WIZARD });
      if (
        userLocationVariable.current.includes(
          USER_TRACKING_LOCATION_NAMES.WORKSPACES
        )
      ) {
        setUserLocationVariable({
          ...userLocationVariable,
          current: userLocationVariable.previous,
          previous: userLocationVariable.current
        });
      }
    } else if (viewRender.stored?.render.type !== HOME_VIEW_TYPES.WIZARD) {
      setViewRender({ ...viewRender, stored: undefined });
    }
    if (!valueIsUndefined) {
      setValue(undefined);
    }
    void navigate(`/${NAVIGATOR_NAMES.WIZARD}`);
  };

  return (
    <>
      <Select
        disabled={disableSelect}
        value={value?.name ?? defaultValue}
        open={openSelect}
        defaultValue={defaultValue}
        IconComponent={KeyboardArrowDown}
        onChange={(event) => {
          if (typeof event.target.value === 'string') {
            const newRecivedValues = event.target.value.split('_');
            const id = newRecivedValues[0];
            const name = newRecivedValues[1];

            if (event.target.value === '') {
              removeSelection();
            } else if (
              id !== undefined &&
              name !== undefined &&
              (valueIsUndefined || value?.id !== id)
            ) {
              executeNewSelection(id, name);
            }
          }
        }}
        onClick={() => {
          if (!disableSelect) {
            setOpenSelect(!openSelect);
          }
        }}
        MenuProps={{
          sx: {
            transform: 'translateY(10px)!important'
          },
          PaperProps: {
            style: { borderRadius: '8px', maxHeight: 200, overflowY: 'auto' },
            onScroll: (event: Event) => {
              const { target } = event;
              if (target !== undefined && target !== null) {
                const { scrollHeight, scrollTop, clientHeight } =
                  target as Element;
                const isBottom = scrollHeight - scrollTop === clientHeight;
                if (
                  isBottom &&
                  displayOptions !== undefined &&
                  page * localAmount < displayOptions.collectionSize
                ) {
                  if (!loading) {
                    setLoading(true);
                    handleRequests(page + 1, localAmount);
                  }
                }
              }
            }
          }
        }}
        renderValue={(value): ReactNode => {
          if (value !== undefined) {
            return (value as string).split('_')[1] ?? value;
          }
        }}
        {...restPops}
        className={`${restPops?.className ?? ''} ${
          valueIsUndefined ? 'select-scroll-menu' : 'selected-scroll-menu'
        }`}
      >
        <MenuItem
          disabled={value === undefined}
          className="select-menu__li li-default"
          value=""
        >
          {defaultValue}
        </MenuItem>
        {displayOptions?.data.map((option, index) => (
          <MenuItem
            className="select-menu__li"
            key={`${option.id}-${index}`}
            value={`${option.id}_${option.name}`}
          >
            {option.name}
          </MenuItem>
        ))}
        {loading && (
          <MenuItem className="select-menu__li" disabled>
            <CircularProgress size={20} sx={{ alignSelf: 'center' }} />
          </MenuItem>
        )}
      </Select>
    </>
  );
};

export default SelectScrollFetch;
