import { useEffect, useMemo, useRef, useState, type ReactElement } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Route, Routes } from 'react-router';
import { Box, Typography } from '@mui/material';

import { AuthenticationService } from 'services/authentication/AuthenticationService';

import { homeViewRenderAtom } from './atoms/atomActivedChat';
import { HOME_VIEW_TYPES, NAVIGATOR_NAMES } from 'common/interfaces/enums';

import Chat from 'chat/Chat';
import RenderViewManager from './components/renderView/RenderViewManager';
import { BOT_COMPONENT, LOADING_STATE } from 'chat/interfaces/enums';
import ChatConnection from 'chat/ChatConnection';
import { chatMessageAtom } from 'chat/atoms/atomChatMessages';
import { magicMessageDerived } from 'chat/atoms/atomMagicMessage';
import type { CustomMessage, MessageMetaData } from 'chat/interfaces/messages';
import RenderViewComposed from './components/renderView/RenderView';
import WorkspacesChat from 'home/components/WorkspacesChat';

const pendingMessagesStack: MessageMetaData[] = [];
export const messageAnimationTime = 800;

export const catchAnimationFailed = (): void => {
  throw new Error('MessagesWithAnimation failed');
};

const Home = (): ReactElement => {
  const authenticationService = AuthenticationService.getInstance();
  const viewRender = useRecoilValue(homeViewRenderAtom);
  const setMessages = useSetRecoilState(chatMessageAtom);
  const setMagicMessage = useSetRecoilState(magicMessageDerived);
  const timeoutIDs: NodeJS.Timeout[] = useMemo(() => [], []);

  const [loading, setLoading] = useState<LOADING_STATE>(LOADING_STATE.IDLE);
  const animationInProgress = useRef<boolean>(false);

  useEffect(() => {
    return () => {
      if (timeoutIDs.length > 0) {
        timeoutIDs.forEach((element) => {
          clearTimeout(element);
        });
      }
      authenticationService.unauthenticate();
    };
  }, []);

  const recursioned = async (pro: {
    _messages: CustomMessage[];
    isMagicMessage?: boolean;
    index: number;
    delay?: number;
  }): Promise<void> => {
    const { _messages, isMagicMessage, delay } = pro;
    for (const _message of _messages) {
      await new Promise((resolve) => {
        const timeoutID: NodeJS.Timeout = setTimeout(() => {
          pro.index = 1 + pro.index;
          animationInProgress.current = false;
          if (isMagicMessage !== undefined && isMagicMessage) {
            setMagicMessage(_message);
          } else {
            if (BOT_COMPONENT.PROGRESS_BAR === _message.component?.type) {
              setLoading(LOADING_STATE.PROCESSING_DATA);
            } else {
              setLoading(LOADING_STATE.WAITING_MESSAGE);
            }
            setMessages((prevMessages: CustomMessage[]) => {
              const oldMessages = prevMessages;
              if (
                _message.component !== undefined &&
                _message.component.type === BOT_COMPONENT.PROGRESS_BAR &&
                !Array.isArray(_message.component.options) &&
                _message.component.options !== undefined &&
                _message.component.options.progress !== '0'
              ) {
                oldMessages.pop();
              } else if (
                _message.complement?.showMore !== undefined &&
                Array.isArray(_message.complement?.showMore) &&
                _message.complement?.showMore.length > 0
              ) {
                const lastMessage = oldMessages.pop();
                if (lastMessage !== undefined) {
                  lastMessage.showMore = _message.complement.showMore;
                  return [...oldMessages, lastMessage];
                }
              }
              return [...oldMessages, _message];
            });
          }
          resolve(timeoutID);
        }, delay ?? messageAnimationTime);
        timeoutIDs.push(timeoutID);
      });
    }
  };

  const createAnimatedMessages = async (
    messageData: MessageMetaData
  ): Promise<void> => {
    const { isMagicMessage, _messages, delay } = messageData;
    if (_messages.length === 0) return;
    if (animationInProgress.current) {
      pendingMessagesStack.push(messageData);
      return;
    }
    animationInProgress.current = true;
    const pro = { _messages, isMagicMessage, index: 0, delay };
    await recursioned(pro);
    while (
      !animationInProgress.current &&
      pendingMessagesStack.length > 0 &&
      _messages.length === pro.index
    ) {
      const pending = pendingMessagesStack.shift();
      if (pending !== undefined) {
        createAnimatedMessages(pending).catch(catchAnimationFailed);
      }
    }
  };

  return (
    <Box id="home-container">
      <ChatConnection createAnimatedMessages={createAnimatedMessages} />
      {viewRender.type !== HOME_VIEW_TYPES.WIZARD &&
      viewRender.type !== HOME_VIEW_TYPES.WORKSPACES ? (
        <Box id="home-container__myspace">
          <RenderViewManager />
        </Box>
      ) : null}
      <Routes>
        <Route
          path={`/${NAVIGATOR_NAMES.WIZARD}`}
          element={
            <Chat
              loading={loading}
              createAnimatedMessages={createAnimatedMessages}
            />
          }
        />
        <Route
          path={`/${NAVIGATOR_NAMES.WORKSPACES}/:id`}
          element={
            <RenderViewComposed
              id="workspace"
              header={
                <Box>
                  <Typography>Workspace</Typography>
                </Box>
              }
              body={
                <Box>
                  <WorkspacesChat />
                </Box>
              }
            />
          }
        />
      </Routes>
    </Box>
  );
};

export default Home;
