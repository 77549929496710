import React, { type ReactComponentElement, useEffect, useState } from 'react';
import ChartBars from '../bars/ChartBars';
import ChartLine from '../line/ChartLine';
import './chartsInsights.scss';

interface PlotData {
  x: Array<string | number>;
  y: number[];
}
interface HistplotData {
  x: number[];
  y: number[];
}
interface Props {
  insightData: string;
  column: string;
  variable: string;
  size?: 'default' | 'large';
}

type Originaldata = Record<string, { x: Array<string | number>; y: number[] }>;
type MappdedData = Array<Array<string | number>>;
type TransformedData = Record<string, Array<string | number>>;

const ChartsInsights: React.FC<Props> = (props) => {
  const [trasnformedData, setTransformedData] = useState<TransformedData>({});

  const transformHistogramData = (
    key: string,
    data: Originaldata
  ): MappdedData => {
    const histPlotData = data[key] as HistplotData;
    const frequencies = new Array(histPlotData.y.length - 1).fill(0);

    histPlotData.x.forEach((value: number): void => {
      for (let i = 0; i < histPlotData.y.length - 1; i++) {
        if (value >= histPlotData.y[i] && value < histPlotData.y[i + 1]) {
          frequencies[i]++;
          break;
        }
      }
    });

    const total = frequencies.reduce(
      (sum: number, value: number) => sum + value,
      0
    );
    const percentages = frequencies.map((value) => (value / total) * 100);

    const histPlotDataMapped = percentages.map((freq, index) => {
      const yValue = (histPlotData.y[index] + histPlotData.y[index + 1]) / 2;
      return [Math.round(yValue * 100) / 100, freq];
    });

    return histPlotDataMapped;
  };

  const transformFreqData = (key: string, data: PlotData): MappdedData => {
    const x = data.x;
    const y = data.y;

    const chartReadyData = x.map((xValue: string | number, index: number) => [
      xValue,
      y[index] * 100 // transforming to percentage
    ]);

    return chartReadyData;
  };

  const transformBasicData = (key: string, data: PlotData): MappdedData => {
    const x = data.x;
    const y = data.y;

    const chartReadyData = x.map((xValue: string | number, index: number) => [
      xValue,
      y[index]
    ]);

    return chartReadyData;
  };

  const transformData = (data: Originaldata): void => {
    let newTransformData = {};
    for (const key in data) {
      if (key === 'hist_plot') {
        newTransformData = {
          ...newTransformData,
          [key]: transformHistogramData(key, data)
        };
      } else if (key === 'bar_plot_freq') {
        newTransformData = {
          ...newTransformData,
          [key]: transformFreqData(key, data[key])
        };
      } else {
        newTransformData = {
          ...newTransformData,
          [key]: transformBasicData(key, data[key])
        };
      }
    }

    setTransformedData(newTransformData);
  };

  useEffect(() => {
    fetch(props.insightData)
      .then(async (res: Response) => await res.json())
      .then((d) => {
        transformData(d);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [props.insightData]);

  const addCorrectPlot = (
    i: number
  ): ReactComponentElement<typeof ChartLine | typeof ChartBars> => {
    // If is bar_plot, bar_plot_freq or histo_plot we will use bar charts
    const key = Object.keys(trasnformedData)[i];
    return key === 'line_plot' ? (
      <ChartLine
        plotType={key}
        plotData={trasnformedData}
        size={props.size}
        yLabel={props.column}
        xLabel={props.variable}
      />
    ) : (
      <ChartBars
        plotType={key}
        plotData={trasnformedData}
        size={props.size}
        yLabel={props.column}
        xLabel={props.variable}
        yAxisSufix={key === 'bar_plot_freq' || key === 'hist_plot' ? '%' : ''}
      />
    );
  };

  const insightPlotsTitle = (): string => {
    const titlePercentage = `Percentage of ${props.column} per ${props.variable}`;
    const titleTrend = `${props.column} trend per ${props.variable}`;
    return Object.keys(trasnformedData).includes('line_plot')
      ? titleTrend
      : titlePercentage;
  };

  return (
    <div
      className={`insight-plots ${
        props.size === 'large' ? 'large' : 'default'
      }`}
    >
      <div className="insight-plots__title">{insightPlotsTitle()}</div>
      <div className="insight-plots__container">
        <div className="insight-plot insight-plot--vars">
          {addCorrectPlot(0)}
        </div>
        <div className="insight-plot insight-plot--freq">
          {addCorrectPlot(1)}
        </div>
      </div>
    </div>
  );
};

export default ChartsInsights;
