import type { Dispatch, SetStateAction } from 'react';
import type { ContextSelection } from '../atoms/atomContextSelections';
import type { WarningUpload } from '../UploadManager';

export enum LLM_INFO_STRINGS {
  ENABLE_LLM_TITLE_INFO_ALERT = 'Note that, once the dataset is saved you will be able to perform optimisations and predictions but the process to generate all analysis in order to get in chat insights would take short time as we need to run complex computations.',
  DISABLE_LLM_TITLE_WARNING_ALERT = 'Missing Information for Machine Learning Operations',
  DISABLE_LLM_BODY_WARNING_ALERT = 'To process your data and enable advanced insights through our assistant, we need at least 1 output column and 5 context-filled columns to proceed.',
  DISABLE_LLM_BODY_DETAIL_DATASET_WARNING_ALERT = 'To process your data and enable advanced insights through our assistant, we need at least 1 output column and 5 context-filled columns to be provided. Fill this information on the “Edit context” option under “Data management” for any dataset.',
  MISSING_CONTEXT_OUTPUT_COLUMNS_TITLE = 'Missing context for output columns',
  MISSING_INFORMATION_LLM = 'Missing Information for Machine Learning Operations',
  CONTEXT_FIELDS_EMPTY = 'Context fields empty',
  LLM_STATUS_PROCESSING_TITLE = 'Dataset and analysis are being processed',
  LLM_STATUS_PROCESSION_BODY = 'We are creating various analysis and models in order for you to get the most of your data. Bear in mind that this process will take a little while as we are processing complex computations. \n\nOnce the dataset is fully processed the “Ask anything” section will be accessible to start gathering insights.',
  SEND_EMAIL_SUCCESS = 'You will be notified once the process has been completed'
}

export const checkValidityOfSchemaContext = (
  contextSelection: ContextSelection,
  setWarning: Dispatch<SetStateAction<WarningUpload | undefined>>
): boolean => {
  let isMissing = false;
  let foundEmptyContext = false;
  let checkCount = 0;
  let valuesCount = 0;
  contextSelection.forEach((existingSelection, ind) => {
    if (existingSelection.checked) {
      checkCount++;
      if (existingSelection.description === '') {
        foundEmptyContext = true;
      }
    }
    if (existingSelection.description !== '') {
      valuesCount++;
    }
  });
  if (foundEmptyContext) {
    isMissing = true;
    setWarning({
      title: LLM_INFO_STRINGS.MISSING_CONTEXT_OUTPUT_COLUMNS_TITLE,
      body: [
        'Context is required for output features to be analysed.',
        `Please add context to each of the columns selected as outputs or
              remove those without context. Remember, output columns are those
              that will be taken into account for our analysis as your main
              goals.`
      ],
      allowContinue: false
    });
  } else if (!(checkCount > 0 && valuesCount > 4)) {
    isMissing = true;
    setWarning({
      title: LLM_INFO_STRINGS.MISSING_INFORMATION_LLM,
      body: [
        `To process your data and enable advanced insights through our
            assistant, we need at least 1 output column and 5 context-filled
            columns to proceed.`
      ],
      allowContinue: true
    });
  } else if (contextSelection.length !== valuesCount) {
    isMissing = true;
    setWarning({
      title: LLM_INFO_STRINGS.CONTEXT_FIELDS_EMPTY,
      body: [
        `If not all the context fields for columns are filled, it would be
            more difficult for the system to analyse your data and the
            assistant to answer your questions.`
      ],
      allowContinue: true
    });
  }
  return isMissing;
};

export const isMissingInformation = (
  contextSelection: ContextSelection
): boolean => {
  let checkCount = 0;
  let valuesCount = 0;

  contextSelection.forEach((existingSelection) => {
    if (existingSelection.checked) {
      checkCount++;
    }
    if (existingSelection.description !== '') {
      valuesCount++;
    }
  });
  return !(checkCount > 0 && valuesCount > 4);
};
