import { homeViewRenderAtom } from 'home/atoms/atomActivedChat';
import { useEffect, useRef } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { lastResponseAtom } from './atoms/atomLastResponse';
import { modalControllerAtom } from 'atoms/atomModalController';
import { magicMessageDerived } from './atoms/atomMagicMessage';
import { chatMessageAtom } from './atoms/atomChatMessages';
import { AssistantApiService } from './services/AssistantApiService';
import { AssistantApiSocketClient } from 'services/AssistantApiSocketClient';
import type {
  Blocks,
  BlocksAccessory,
  ChatComponent,
  CustomMessage,
  DataUrlResponse,
  InsightsMessage,
  MessageMetaData,
  ReceivedMessage
} from './interfaces/messages';
import {
  TRACKED_USER_ACTION_OPTIMISE_FLOW,
  TRACKED_USER_ACTION_PREDICT_FLOW,
  USER_TRACKING_LOCATION_NAMES,
  userTrackingLocation
} from 'atoms/atomUserLocation';
import {
  compareLastReceivedResponses,
  finishedProgressBar
} from './utils/utils';
import JSON5 from 'json5';
import { v4 as uuidv4 } from 'uuid';

import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import {
  BOT_COMPONENT,
  BOT_MESSAGE,
  COMPONENT_TYPE,
  TYPES,
  USER_MESSAGE
} from './interfaces/enums';
import { AuthenticationService } from 'services/authentication/AuthenticationService';
import {
  BACKEND_STRINGS,
  HOME_VIEW_TYPES,
  MODAL_TYPES
} from 'common/interfaces/enums';
import { payloadParser } from './utils/payloadUtils';
import {
  deepCopy,
  downloadDocumentFromUrl,
  formatBytes,
  GAUserEvent
} from 'utils/utils';
import { getFileSize } from 'services/DownloadService';
import { catchAnimationFailed } from 'home/Home';

const ChatConnection = ({
  createAnimatedMessages
}: {
  createAnimatedMessages: (msg: MessageMetaData) => Promise<void>;
}): null => {
  const authenticationService = AuthenticationService.getInstance();
  const assistantApiSocketClient = AssistantApiSocketClient.getInstance();
  const assistantApiService = AssistantApiService.getInstance();

  const messages = useRecoilValue(chatMessageAtom);
  const [viewRender, setViewRender] = useRecoilState(homeViewRenderAtom);
  const [lastResponse, setLastResponse] = useRecoilState(lastResponseAtom);
  const [userLocationVariable, setUserLocationVariable] =
    useRecoilState(userTrackingLocation);

  const setMagicMessage = useSetRecoilState(magicMessageDerived);
  const setModalController = useSetRecoilState(modalControllerAtom);

  const lastMessageIsCheckBox = useRef<boolean>(false);
  const lastMessageDuplicate = useRef<boolean>(false);
  const messagesReceived = useRef<Array<Blocks | CustomMessage>>([]);
  const messagesProcessed = useRef<number[]>([]);

  useEffect(() => {
    if (messages?.length === 1) {
      if (authenticationService.isAuthenticated()) {
        assistantApiService.startConversation();
      } else if (!authenticationService.isAuthenticated()) {
        assistantApiService.endConversation();
        authenticationService.unauthenticate();
      }
    }

    const lastMessage = messages[messages.length - 1];
    const preLastMessage = messages[messages.length - 2];

    if (messages.length > 2) {
      const preLastMessageBool = preLastMessage?.message === 'End analysis';
      const lastMessageBool =
        lastMessage?.component?.action_id === 'Start analysis';
      if ((preLastMessageBool && lastMessageBool) || lastMessageBool) {
        authenticationService.unauthenticate();
        assistantApiService.endConversation();
      }
    }

    if (
      lastMessageDuplicate.current &&
      compareLastReceivedResponses(
        messagesReceived.current[messagesReceived.current.length - 1],
        lastResponse
      )(true)
    ) {
      // in this part of the code we are checking if the last message received from the backend was flagged as duplicate
      // in case it was flagged we will check the context of the user interaction to determine the legitimacy of the duplicate
      const lastUserMessages = messages.filter(
        (msg) => msg.from === USER_MESSAGE.MESSAGE
      );
      if (lastUserMessages.length > 2) {
        const lastUserMessage = lastUserMessages[lastUserMessages.length - 1];
        const preLastUserMessage =
          lastUserMessages[lastUserMessages.length - 2];
        if (lastUserMessage.message === preLastUserMessage.message) {
          lastMessageDuplicate.current = false;
          processReceivedBlocks({ response: lastResponse });
        }
      }
    }

    finishedProgressBar(preLastMessage, lastMessage);

    assistantApiSocketClient.addInteractiveMessageListener(
      receivedBlocksHandler
    );
    return () => {
      assistantApiSocketClient.removeInteractiveMessageListener(
        receivedBlocksHandler
      );
    };
  }, [messages]);

  const receivedBlocksHandler = (msg: ReceivedMessage): void => {
    const isLastAckedMessage: boolean =
      assistantApiService.isLastAckedMessage(msg);
    assistantApiService.sendAckMessage(msg);
    if (
      !isLastAckedMessage &&
      msg.response !== null &&
      !('result' in msg.response)
    ) {
      lastMessageDuplicate.current =
        lastResponse.blocks.length > 0
          ? compareLastReceivedResponses(msg.response, lastResponse)()
          : false;
      localStorage.setItem('lastMessageStorage', JSON.stringify(msg.response));
      if (!lastMessageDuplicate.current && msg.code === 200) {
        processReceivedBlocks(msg);
      }
    }
  };

  const categorizeShowMessagesInBlocks = (
    blocks: Blocks['blocks']
  ): boolean => {
    let isShowMessage = false;
    if (Array.isArray(blocks)) {
      isShowMessage = blocks.every(
        (message: {
          accessory?: BlocksAccessory;
          visible: boolean;
          block_id?: string;
        }) => {
          if (
            message.accessory?.alt_text !== undefined &&
            message.accessory?.type === BOT_COMPONENT.IMAGE.toLowerCase()
          ) {
            message.visible = false;
            return true;
          }
          if (
            message.accessory?.action_id ===
              BACKEND_STRINGS.PRESENT_TIMEOUT_1_BUTTONS ||
            message?.block_id === BACKEND_STRINGS.START_ANALYSIS_MENU_OPTION
          ) {
            setModalController({ type: MODAL_TYPES.CLOSE });
          }
          return false;
        }
      );
    }
    if (isShowMessage) {
      const payloadMessage = {
        type: TYPES.CONTEXT,
        [COMPONENT_TYPE.COMPLEMENT]: true,
        showMore: blocks
      };
      const parsedBlock = payloadParser(payloadMessage, {
        messagesProcessed,
        lastMessageIsCheckBox,
        setModalController,
        viewRenderController: [viewRender, setViewRender],
        userLocationController: [userLocationVariable, setUserLocationVariable]
      });
      createAnimatedMessages({ _messages: parsedBlock }).catch(
        catchAnimationFailed
      );
    }
    return isShowMessage;
  };

  const saveReceivedBlocks = (blocks: Blocks['blocks']): void => {
    for (let index = 0; index < blocks.length; index++) {
      const block = blocks[index];
      blocks[index] = payloadParser(
        block,
        {
          messagesProcessed,
          lastMessageIsCheckBox,
          setModalController,
          viewRenderController: [viewRender, setViewRender],
          userLocationController: [
            userLocationVariable,
            setUserLocationVariable
          ]
        },
        { blocks, index }
      );
    }

    if (Array.isArray(blocks) && blocks.length > 0) {
      const flatenedBlocks = blocks.flat(2);
      const foundExplanation = flatenedBlocks.find(
        (fl: { component: { alt_text: string } }) => {
          if (fl?.component?.alt_text !== undefined) {
            return fl.component.alt_text.startsWith('explanation');
          }
          return false;
        }
      );

      if (flatenedBlocks.length > 0 && foundExplanation === undefined) {
        const lastBlock = {
          isMagicComponent: false,
          component: flatenedBlocks[flatenedBlocks.length - 1]
        };

        if (lastBlock.component?.from === BOT_COMPONENT.COMPONENT) {
          lastBlock.isMagicComponent = true;
          flatenedBlocks.pop();
        }

        createAnimatedMessages({ _messages: flatenedBlocks }).catch(
          catchAnimationFailed
        );

        if (lastBlock.isMagicComponent) {
          createAnimatedMessages({
            isMagicMessage: true,
            _messages: [lastBlock.component]
          }).catch(catchAnimationFailed);
        }
      }
    }
  };

  const saveReceivedMessage = (
    response: Blocks,
    newMessage?: CustomMessage
  ): void => {
    const messageReceived = newMessage ?? response;
    localStorage.setItem('file_correlations', 'FALSE');
    setLastResponse(response);
    messagesReceived.current = [...messagesReceived.current, messageReceived];
    if (messagesReceived.current.length > 0) {
      messagesReceived.current.forEach((element, index: number) => {
        const messageProcessed: boolean =
          messagesProcessed.current.includes(index);
        if (!messageProcessed && element !== undefined) {
          messagesProcessed.current = [...messagesProcessed.current, index];
          if (
            'blocks' in element &&
            Array.isArray(element.blocks) &&
            element.blocks.length > 0
          ) {
            const blocks = deepCopy(element.blocks);
            const isShowMessage = categorizeShowMessagesInBlocks(blocks);
            if (!isShowMessage) {
              saveReceivedBlocks(blocks);
            }
          } else if ('base64' in element) {
            createAnimatedMessages({ _messages: [element] }).catch(
              catchAnimationFailed
            );
          }
        }
      });
    }
  };

  // Marked as any type because this message doesn't follow the blocks format we had
  const saveInsightsMessage = (insights: InsightsMessage): void => {
    const insightsMessage: CustomMessage = {
      id: uuidv4(),
      from: BOT_MESSAGE.OPEN_MODAL,
      title: 'Your optimise insights are ready',
      message: 'Open Insights',
      visible: true,
      style: {},
      disable: false,
      icon: BarChartOutlinedIcon,
      base64: false,
      action: async () => {
        GAUserEvent(
          `${userLocationVariable.current}_${TRACKED_USER_ACTION_OPTIMISE_FLOW.OPEN_INSIGHTS}`
        );
        setUserLocationVariable({
          ...userLocationVariable,
          current:
            userLocationVariable.current +
            '_' +
            USER_TRACKING_LOCATION_NAMES.INSIGHTS,
          previous: userLocationVariable.current
        });
        setViewRender({
          type: HOME_VIEW_TYPES.INSIGHTS,
          payload: insights,
          stored: viewRender.stored
        });
      }
    };
    saveReceivedMessage({ blocks: [] }, insightsMessage);
  };

  const saveReceivedUrl = async (message: ReceivedMessage): Promise<void> => {
    const messageResponse = message.response as DataUrlResponse;
    let fileName: string = messageResponse.data_link.split('/').pop() ?? '';
    const documentUrl = messageResponse.data_link;
    const description = messageResponse.description ?? '';
    const modelName = messageResponse.modelName ?? '';
    const targetVariable = messageResponse.targetVariable ?? '';
    const features = messageResponse.features ?? '';

    const fileSizeString = await getFileSize(messageResponse.data_link)
      .then((fileSize) => {
        if (fileSize >= 0) {
          return formatBytes(fileSize);
        }

        return 'Unknown size';
      })
      .catch((error) => {
        console.log(error);
        return 'Unknown size';
      });

    if (targetVariable !== '') {
      const bulkPredictionMessage: CustomMessage = {
        id: uuidv4(),
        from: BOT_MESSAGE.OPEN_MODAL,
        title: 'Open the insights to view your predictions',
        message: 'Analysis Predictions',
        visible: true,
        style: {},
        disable: false,
        icon: BarChartOutlinedIcon,
        base64: false,
        action: async () => {
          GAUserEvent(
            `${userLocationVariable.current}_${TRACKED_USER_ACTION_PREDICT_FLOW.BULK_PREDICTIONS}`
          );
          setViewRender({
            type: HOME_VIEW_TYPES.BULK_PREDICTIONS,
            payload: {
              asset: {
                description,
                targetVariable,
                modelName,
                documentUrl,
                features
              }
            },
            stored: viewRender.stored
          });
        }
      };
      saveReceivedMessage({ blocks: [] }, bulkPredictionMessage);
    }

    fileName =
      fileName.length > 23 ? `${fileName.substring(0, 20)}...` : fileName;
    const fileMessage: CustomMessage = {
      id: uuidv4(),
      from: BOT_MESSAGE.FILE,
      message: fileSizeString.toString(),
      visible: true,
      style: {},
      title: fileName,
      disable: false,
      icon: InsertDriveFileOutlinedIcon,
      base64: false,
      action: async () => {
        downloadDocumentFromUrl(documentUrl, fileName);
      }
    };
    saveReceivedMessage({ blocks: [fileName] }, fileMessage);
  };

  const processReceivedBlocks = (msg: ReceivedMessage): void => {
    if (msg.mimetype === 'url') {
      void saveReceivedUrl(msg);
    } else if (msg.mimetype === 'insights' && 'insights' in msg.response) {
      saveInsightsMessage(msg.response.insights);
    } else if ('blocks' in msg.response && msg.response.blocks.length > 0) {
      saveReceivedMessage(msg.response);
    } else if (
      'accessory' in msg.response &&
      msg.response.accessory?.type ===
        BOT_COMPONENT.MULTI_SELECT.toLowerCase() &&
      typeof msg.response.accessory?.options === 'string'
    ) {
      const parsedOptions: ChatComponent['options'] = JSON5.parse(
        msg.response.accessory.options
      );
      msg.response.accessory.options = parsedOptions;
      if (typeof msg.response.accessory.options !== 'string') {
        const multiSelectMessage = {
          id: uuidv4(),
          from: BOT_COMPONENT.COMPONENT,
          visible: true,
          component: msg.response.accessory,
          title: msg.response.accessory.text
        };
        setMagicMessage(multiSelectMessage as CustomMessage);
      }
    }
  };

  return null;
};

export default ChatConnection;
