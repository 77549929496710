import { useEffect, useState, type ReactElement } from 'react';

import { Alert, Box, Divider, Snackbar, Typography } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as LoadingDots } from 'assets/imgs/loading-dots.svg';
import ChartCorrelations from 'common/charts/correlations/chartCorrelations';
import Spinner from 'common/Spinner';
import { HOME_VIEW_TYPES, ICON_TYPE } from 'common/interfaces/enums';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import Feature from './components/feature/Feature';
import PredictScenario from './components/predictScenario/PredictScenario';

import { previewfiltersStateAtom } from 'bulkPredictions/atoms/atomPreviewfilersState';
import {
  defaultFeatureValues,
  featureValuesAtom,
  featuresAtom,
  loadingAtom,
  loadingJobAtom,
  modelFeaturesHighImportanceSelector,
  runningButtonTextAtom,
  scenarioAtom,
  showAllFeaturesButtonSelector,
  snackBarAtom,
  transformedPlotDataSelector
} from './atoms/atomPlayground';
import type { DataLayerOptions } from './renderLayer/PlaygroundDataLayerRender';
import { ScenarioSelectors, type Plot } from './interfaces/playground';

import {
  TRACKED_USER_ACTION_PLAYGROUND,
  USER_TRACKING_LOCATION_NAMES,
  userTrackingLocation
} from 'atoms/atomUserLocation';
import CustomButton from 'common/button/CustomButton';
import { GAUserEvent } from 'utils/utils';
import { homeViewRenderAtom } from 'home/atoms/atomActivedChat';
import './Playground.scss';
import RenderViewComposed from 'home/components/renderView/RenderView';

interface PlaygroundProps {
  fetchData: () => Promise<void>;
  runPredict: () => Promise<void>;
  closeModal: () => void;
  updateFeatureValues: (feature: string, value: string) => void;
  runScenario: (
    selector: string,
    intention: string,
    goal: string
  ) => Promise<void>;
  options: DataLayerOptions;
}

const Playground = ({
  fetchData,
  runPredict,
  closeModal,
  updateFeatureValues,
  runScenario,
  options
}: PlaygroundProps): ReactElement => {
  const { returnValues, filters, selectedRowData } = options;
  const [userLocationVariable, setUserLocationVariable] =
    useRecoilState(userTrackingLocation);
  const [viewRender, setViewRender] = useRecoilState(homeViewRenderAtom);
  const [snackBar, setSnackBar] = useRecoilState(snackBarAtom);

  const features = useRecoilValue(featuresAtom);
  const loading: boolean = useRecoilValue(loadingAtom);
  const loadingPrediction: boolean = useRecoilValue(loadingJobAtom);
  const featureValues = useRecoilValue(featureValuesAtom);
  const plots: Plot = useRecoilValue(transformedPlotDataSelector);
  const featuresHighImportance = useRecoilValue(
    modelFeaturesHighImportanceSelector
  );
  const runningButtonText = useRecoilValue(runningButtonTextAtom);
  const scenarioValues = useRecoilValue(scenarioAtom);
  const showAllFeaturesButton = useRecoilValue(showAllFeaturesButtonSelector);

  const setFilterOptions = useSetRecoilState(previewfiltersStateAtom);
  const setDefaultFeatureValues = useSetRecoilState(defaultFeatureValues);

  const [scenarioText, setScenarioText] = useState<string>('');
  const [showAllFeatures, setShowAllFeatures] = useState<boolean>(false);

  useEffect(() => {
    if (
      userLocationVariable.current !== USER_TRACKING_LOCATION_NAMES.PLAYGROUND
    ) {
      setUserLocationVariable({
        ...userLocationVariable,
        current: USER_TRACKING_LOCATION_NAMES.PLAYGROUND,
        previous: userLocationVariable.current
      });
    }
    void fetchData();
  }, []);

  useEffect(() => {
    if (scenarioValues.update) {
      setScenarioText(getScenarioText());
    }
  }, [scenarioValues.update]);

  const getScenarioText = (): string => {
    const scenarioText =
      scenarioValues.scenario === ScenarioSelectors.BEST
        ? 'Best case scenario'
        : 'Worst case scenario';
    const intentionText =
      scenarioValues.intention === ScenarioSelectors.INCREASE
        ? 'increase'
        : 'decrease';
    const goalText = scenarioValues.goal;

    return `${scenarioText} to ${intentionText} ${goalText}`;
  };

  const generateFeatures = (): ReactElement[] => {
    const featureElements: ReactElement[] = [];

    Object.entries(featuresHighImportance).forEach(([feature, show], index) => {
      if (showAllFeatures || show) {
        const current = features[feature];

        // This can only happen for old models with _ in the feature name
        if (current === undefined) {
          console.error(
            `Feature ${feature} not found in ${JSON.stringify(
              Object.keys(features)
            )}`
          );
          return;
        }
        featureElements.push(
          <Feature
            key={index}
            name={feature}
            type={current.type}
            categoryVariants={current.categoryVariants}
            updateFeatureValues={updateFeatureValues}
            value={featureValues !== undefined ? featureValues[feature] : ''}
            scenario={current.scenario}
          />
        );
      }
    });
    return featureElements;
  };

  const PlaygroundFooter = (
    <>
      <CustomButton
        variant="secondary"
        disabled={loadingPrediction}
        onClick={() => {
          setDefaultFeatureValues(features);
        }}
      >
        Reset
      </CustomButton>
      <CustomButton
        disabled={loadingPrediction || featureValues === undefined}
        onClick={() => {
          if (!loadingPrediction) {
            GAUserEvent(
              `${userLocationVariable.current}_${TRACKED_USER_ACTION_PLAYGROUND.PREDICT}`
            );
            void runPredict();
          }
        }}
      >
        {runningButtonText}
        {loadingPrediction && (
          <>
            <Divider
              orientation="vertical"
              variant="middle"
              style={{ margin: '0px 10px' }}
            />
            <LoadingDots />
          </>
        )}
      </CustomButton>
    </>
  );

  const headerComponent = loading ? null : (
    <Box>
      <Typography style={{ fontSize: 18, fontWeight: 400 }}>
        {returnValues !== undefined
          ? 'Bulk predictions - result playground'
          : 'Validate your hypothesis'}
      </Typography>
      <CloseIcon
        style={{ marginLeft: 'auto', cursor: 'pointer' }}
        onClick={() => {
          GAUserEvent(
            `${userLocationVariable.current}_${TRACKED_USER_ACTION_PLAYGROUND.CLOSE}`
          );
          setUserLocationVariable({
            ...userLocationVariable,
            current: userLocationVariable.previous,
            previous: userLocationVariable.current
          });
          closeModal();
        }}
      />
    </Box>
  );

  const bodyComponent = loading ? (
    <Box className="spinner-box">
      <Spinner />
    </Box>
  ) : (
    <Box id="playground-body">
      <Box id="playground-body__features">
        <Typography style={{ fontSize: 14, width: '100%' }}>
          Here are the are the most impacting features for your prediction.{' '}
          <span
            className={
              scenarioValues.scenario === ScenarioSelectors.BEST
                ? 'feature-best'
                : 'feature-worst'
            }
          >
            {scenarioText}
          </span>
        </Typography>
        {generateFeatures()}
        <Box id="scenario-legend-container">
          Match: <span className="scenario-legend success-legend"></span>
          <Typography className="scenario-legend-text">Best Case</Typography>
          <span className="scenario-legend warning-legend"></span>
          <Typography className="scenario-legend-text">Worst Case</Typography>
        </Box>
        <div style={{ width: '100%' }}></div>
        {showAllFeaturesButton && (
          <CustomButton
            variant="secondary"
            small={true}
            style={{
              marginBottom: '5px',
              marginLeft: '5px'
            }}
            onClick={() => {
              setShowAllFeatures(!showAllFeatures);
            }}
          >
            Show all features
          </CustomButton>
        )}
      </Box>
      <Box id="playground-body__prediction-scenario">
        <PredictScenario runScenario={runScenario} />
      </Box>
      <Box id="playground-body__plot">
        <ChartCorrelations
          dataCorrelations={plots}
          isChat={false}
          chartName={plots.title}
          chartID={'playground_correlation_chart'}
        />
      </Box>
    </Box>
  );

  const footerComponent = loading ? undefined : (
    <Box>
      {returnValues !== undefined ? (
        <>
          <Box className="predictions-from-summary">
            <CustomButton
              variant="secondary"
              className="display-row"
              disabled={loadingPrediction}
              onClick={() => {
                if (filters !== undefined) {
                  setFilterOptions(filters);
                }
                setUserLocationVariable({
                  ...userLocationVariable,
                  current: userLocationVariable.previous,
                  previous: userLocationVariable.current
                });
                setViewRender({
                  type: HOME_VIEW_TYPES.BULK_PREDICTIONS,
                  payload: {
                    selectedRowData,
                    playgroundReturnData: returnValues,
                    newFilters: filters
                  },
                  stored: viewRender.stored
                });
              }}
              icon={{
                type: ICON_TYPE.ARROW_LEFT,
                position: 'left'
              }}
            >
              Return to summary
            </CustomButton>
            <Typography>Return to the prediction summary</Typography>
          </Box>
          <Box className="predictions-from-summary">{PlaygroundFooter}</Box>
        </>
      ) : (
        PlaygroundFooter
      )}
    </Box>
  );

  return (
    <>
      <RenderViewComposed
        id="playground"
        header={headerComponent}
        body={bodyComponent}
        footer={footerComponent}
      />
      <Snackbar
        open={snackBar.open}
        autoHideDuration={10000}
        onClose={() => {
          setSnackBar({ status: 'info', open: false, message: '' });
        }}
      >
        <Alert severity={snackBar.status}>{snackBar.message}</Alert>
      </Snackbar>
    </>
  );
};

export default Playground;
