import { useEffect, useState, type ReactElement } from 'react';
import { Alert, Box, Portal, Snackbar, ThemeProvider } from '@mui/material';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { theme } from 'themes/theme';
import { useNavigate } from 'react-router';
import { decodeJwt } from 'jose';

import { AuthenticationService } from 'services/authentication/AuthenticationService';
import { AssistantApiSocketClient } from 'services/AssistantApiSocketClient';

import ModalController from 'common/modal/ModalController';
import { MODAL_TYPES, NAVIGATOR_NAMES } from 'common/interfaces/enums';

import { notifyMessageAtom } from 'atoms/atomMessageError';
import { modalControllerAtom } from 'atoms/atomModalController';
import { chatActiveAtom } from 'home/atoms/atomActivedChat';

import NavBar from 'home/components/navBar/Navbar';
import Home from './home/Home';
import { Cookie } from 'utils/cookie';
import './App.scss';

const App = (): ReactElement => {
  const navigate = useNavigate();
  const snackBar = useRecoilValue(notifyMessageAtom);
  const resetNotifyMessage = useResetRecoilState(notifyMessageAtom);
  const assistantApiSocketClient = AssistantApiSocketClient.getInstance();
  const authenticationService = AuthenticationService.getInstance();
  const [validToken, setValidToken] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [chatActive, setChatActive] = useRecoilState(chatActiveAtom);
  const [modalController, setModalController] =
    useRecoilState(modalControllerAtom);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    if (searchParams.has('token') && searchParams.has('mode')) {
      setLoading(false);
      setModalController({ type: MODAL_TYPES.RESET_PASSWORD });
    } else {
      void validateToken();
    }
    if (!chatActive) {
      localStorage.removeItem('lastMessageStorage');
    }
    const handleBeforeUnload = (e: BeforeUnloadEvent): boolean => {
      e.preventDefault();
      return true;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [chatActive, validToken]);

  async function validateToken(): Promise<void> {
    const token = Cookie.get('token');

    if (token !== undefined && !validToken) {
      const { exp } = decodeJwt(token);

      if (exp !== undefined && exp * 1000 < Date.now()) {
        Cookie.remove('token');
        setLoading(false);
        if (!chatActive && modalController.type === MODAL_TYPES.CLOSE) {
          setModalController({ type: MODAL_TYPES.START_CHAT });
        }
      } else {
        await authServiceTokenValidation(token);
      }
    } else {
      if (!chatActive && modalController.type === MODAL_TYPES.CLOSE) {
        setModalController({ type: MODAL_TYPES.START_CHAT });
      }
      setLoading(false);
    }
  }

  async function authServiceTokenValidation(token: string): Promise<void> {
    setLoading(true);
    await authenticationService
      .authenticateByToken(token)
      .then(() => {
        assistantApiSocketClient.setAuth(token);
        authenticationService.setAuthToken(token);
        setValidToken(true);
        if (!chatActive) {
          setChatActive(true);
          setLoading(false);
          void navigate(`/${NAVIGATOR_NAMES.WIZARD}`);
        }
      })
      .catch(() => {
        Cookie.remove('token');
        setLoading(false);
        setValidToken(() => false);
      });
  }

  return (
    <div
      style={{
        position: 'absolute',
        width: '100vw',
        height: '100vh',
        top: '0px',
        left: '0px',
        zIndex: 0
      }}
      className="App"
    >
      <ThemeProvider theme={theme}>
        <Box id="app-container">
          <NavBar
            disabled={modalController.type === MODAL_TYPES.START_CHAT}
            user={authenticationService.userData}
            setValidToken={setValidToken}
          />
          {chatActive && !loading ? <Home /> : <Box id="home-container" />}
        </Box>
        <ModalController />
        <Portal>
          <Snackbar
            open={snackBar.open}
            autoHideDuration={10000}
            onClose={() => {
              resetNotifyMessage();
            }}
          >
            <Alert severity={snackBar.status}>{snackBar.message}</Alert>
          </Snackbar>
        </Portal>
      </ThemeProvider>
    </div>
  );
};

export default App;
