import React, {
  useEffect,
  useRef,
  type CSSProperties,
  type ComponentType,
  type ReactElement,
  type ReactNode
} from 'react';
import { useRecoilValue } from 'recoil';

import type {
  AddNewMessageType,
  ChatComponent,
  CustomMessage,
  MessageMetaData,
  TypeAddNewMessage
} from './interfaces/messages';

import {
  BOT_COMPONENT,
  BOT_MESSAGE,
  type LOADING_STATE,
  USER_MESSAGE
} from './interfaces/enums';

import type { RegularComponentTypes } from 'common/interfaces/interfaces';

import { deepCopy, includeURL } from 'utils/utils';

import {
  generateBotMessageWithLink,
  getLinkedComponent
} from './utils/payloadUtils';

import { chatMessageAtom } from './atoms/atomChatMessages';
import { MagicIsland } from './magicIsland/MagicIsland';
import { catchAnimationFailed, messageAnimationTime } from 'home/Home';
import Image from './components/Image/Image';
import LinkPreview from './components/LinkPreview';
import ProgressBar from './components/ProgressBar';
import ShowMore from './components/ShowMore';
import CustomTransition from './components/customTransition/CustomTransition';
import CustomButton from 'common/button/CustomButton';

import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { Link } from '@mui/material';
import { TransitionGroup } from 'react-transition-group';
import { v4 as uuidv4 } from 'uuid';

import audioSrc from 'assets/music/bellRing.mp3';
import useSound from 'use-sound';
import type { PlayFunction } from 'use-sound/dist/types';

import './Chat.scss';

export interface BotComponentSubmit {
  addNewMessages: TypeAddNewMessage;
}
interface ChatProps {
  loading: LOADING_STATE;
  createAnimatedMessages: (msg: MessageMetaData) => Promise<void>;
}

const Chat = ({ loading, createAnimatedMessages }: ChatProps): ReactElement => {
  const messages = useRecoilValue(chatMessageAtom);

  const messageBoxRef = useRef<HTMLDivElement>(null);
  const progressBarVisible = useRef({});

  const [play] = useSound(audioSrc);

  useEffect(() => {
    if (messageBoxRef?.current !== null) {
      messageBoxRef.current.scrollTop = messageBoxRef.current.scrollHeight;
    }
  });

  const addNewMessages: TypeAddNewMessage = (
    newMessage: string | AddNewMessageType,
    type: USER_MESSAGE,
    visibleMessage?: boolean
  ): void => {
    const lMessages: CustomMessage[] = [];
    const messageIsNotObj = typeof newMessage === 'string';
    let _newMessage;
    let visible = true;

    if (messageIsNotObj) {
      _newMessage = newMessage;
    } else {
      _newMessage = newMessage.messages ?? newMessage?.value;
    }

    if (!messageIsNotObj && newMessage.visible !== undefined) {
      visible = !!newMessage.visible;
    } else if (visibleMessage !== undefined) {
      visible = visibleMessage;
    }

    if (
      type === USER_MESSAGE.MESSAGE &&
      !messageIsNotObj &&
      newMessage.valueObject !== undefined
    ) {
      lMessages.push({
        id: uuidv4(),
        from: USER_MESSAGE.FILE,
        message: _newMessage,
        visible,
        icon: InsertDriveFileOutlinedIcon,
        title: newMessage?.title
      });
    } else if (type === USER_MESSAGE.MESSAGE) {
      lMessages.push({
        id: uuidv4(),
        from: USER_MESSAGE.MESSAGE,
        message: _newMessage,
        visible
      });
      if (
        !messageIsNotObj &&
        Array.isArray(newMessage.messages) &&
        newMessage.messages.length > 0
      ) {
        newMessage.messages.forEach((ms: CustomMessage) => {
          lMessages.push({
            id: uuidv4(),
            from: ms.from,
            message: ms.message,
            visible: ms.visible,
            title: ms?.title,
            icon: ms?.icon,
            component: ms?.component
          });
        });
      }
    } else {
      lMessages.push({
        id: uuidv4(),
        from: BOT_MESSAGE.MESSAGE,
        message: _newMessage,
        visible
      });
    }
    if (lMessages.length > 0) {
      createAnimatedMessages({ _messages: lMessages }).catch(
        catchAnimationFailed
      );
    }
  };

  const generateWrappedModalMessage = (
    WrapperComponent: ComponentType<RegularComponentTypes>,
    message: CustomMessage
  ): ReactElement => {
    const CUSTOM_ICON: undefined | ComponentType<{ style: CSSProperties }> =
      message.icon;
    let iconComponent = <></>;

    if (CUSTOM_ICON !== undefined) {
      iconComponent = (
        <div className="message-cicrled-iconComponent">
          <CUSTOM_ICON style={{}} />
        </div>
      );
    }

    return (
      <WrapperComponent
        key={uuidv4()}
        style={{ marginTop: '-10px', alignItems: 'flex-start' }}
      >
        {iconComponent}
        <div
          style={{
            flexDirection: 'column-reverse',
            paddingLeft: '10px',
            alignItems: 'flex-start'
          }}
        >
          <div className="user-message-2body">{message.title}</div>
          <CustomButton onClick={message.action}>
            {message.message}
          </CustomButton>
        </div>
      </WrapperComponent>
    );
  };

  const generateWrappedMessage = (
    WrapperComponent: ComponentType<RegularComponentTypes>,
    message: CustomMessage,
    messageType: 'link' | 'file'
  ): ReactElement => {
    const CUSTOM_ICON: undefined | ComponentType<{ style: CSSProperties }> =
      message.icon;
    let iconComponent = <></>;
    const isFileType = messageType === 'file';

    if (CUSTOM_ICON !== undefined) {
      iconComponent = (
        <div style={{ flexDirection: 'row' }}>
          {isFileType ? (
            <CustomButton onClick={message.action}>
              <div
                style={{
                  width: '35px',
                  height: '35px',
                  left: '0px',
                  top: '8.5px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: 'rgba(255, 255, 255, 0.4)',
                  border: '4px solid rgba(255, 255, 255, 0.2)',
                  borderRadius: '28px'
                }}
              >
                <CUSTOM_ICON
                  style={{
                    transform: 'scale(0.9)',
                    color: '#ffffff'
                  }}
                />
              </div>
            </CustomButton>
          ) : (
            <CUSTOM_ICON style={{ transform: 'rotate(-45deg) scale(1.25)' }} />
          )}
        </div>
      );
    }

    return (
      <WrapperComponent key={uuidv4()} style={{ marginTop: '-10px' }}>
        {iconComponent}
        <div style={{ flexDirection: 'column' }}>
          <div
            className={isFileType ? 'user-message-2body' : 'bot-message-2body'}
          >
            {message.title}
          </div>
          {isFileType ? (
            <div
              className="user-message-2body"
              style={{
                fontSize: '14px',
                lineHeight: '20px',
                textDecorationColor: '#ffffff'
              }}
            >
              {message.message}
            </div>
          ) : (
            <Link
              className="bot-message-2link"
              style={{
                fontSize: '12px',
                lineHeight: '18px',
                textDecorationColor: '#ffffff'
              }}
              href={message.message}
              target="_blank"
            >
              {message.message}
            </Link>
          )}
        </div>
      </WrapperComponent>
    );
  };

  const generateWrappedComponentByMessage = (
    WrapperComponent: ComponentType<RegularComponentTypes> | null,
    message: CustomMessage,
    index: number
  ): ReactElement => {
    let _finalComponent: ReactNode | null = <></>;
    const messageFrom = message.from ?? '';
    if (
      messageFrom === BOT_COMPONENT.PROGRESS_BAR &&
      message.component?.type !== undefined &&
      WrapperComponent !== null
    ) {
      const customComp = generateCustomComponent(
        ProgressBar,
        message.component,
        index,
        {
          options: message.component?.options,
          sound: play,
          style: { minWidth: '50%' },
          componentprogressBarIndexes: progressBarVisible.current,
          visibleMessage: false
        }
      );
      _finalComponent = (
        <WrapperComponent keys={uuidv4()}>{customComp}</WrapperComponent>
      );
    } else if (
      messageFrom === BOT_COMPONENT.IMAGE &&
      message.component?.type !== undefined &&
      WrapperComponent !== null
    ) {
      const customComp = generateCustomComponent(
        Image,
        message.component,
        index,
        {
          options: message.component?.options,
          style: {
            minWidth: '50%',
            marginTop: '-15px'
          }
        }
      );
      _finalComponent = (
        <WrapperComponent keys={uuidv4()}>{customComp}</WrapperComponent>
      );
    } else if (
      messageFrom === BOT_MESSAGE.MESSAGE_2 &&
      message.title !== undefined &&
      WrapperComponent !== null
    ) {
      _finalComponent = generateWrappedMessage(
        WrapperComponent,
        message,
        'link'
      );
    } else if (
      (messageFrom === USER_MESSAGE.FILE || messageFrom === BOT_MESSAGE.FILE) &&
      message.title !== undefined &&
      WrapperComponent !== null
    ) {
      _finalComponent = generateWrappedMessage(
        WrapperComponent,
        message,
        'file'
      );
    } else if (
      messageFrom === BOT_MESSAGE.OPEN_MODAL &&
      message.title !== undefined &&
      WrapperComponent !== null
    ) {
      _finalComponent = generateWrappedModalMessage(WrapperComponent, message);
    } else if (
      messageFrom === BOT_MESSAGE.LINK_PREVIEW &&
      message.message !== undefined &&
      WrapperComponent !== null
    ) {
      const messageTextBool: boolean = message.message.includes(
        'preparing-your-data'
      );
      const orientation = messageTextBool ? 'horizontal' : 'vertical';
      _finalComponent = (
        <WrapperComponent keys={uuidv4()} style={message.style}>
          <LinkPreview url={message.message} orientation={orientation} />
        </WrapperComponent>
      );
    } else if (
      messageFrom === BOT_MESSAGE.MESSAGE &&
      message.message !== undefined &&
      WrapperComponent !== null
    ) {
      if (message.showMore !== undefined) {
        _finalComponent = (
          <ShowMore
            CustomComponent={WrapperComponent}
            message={message}
            showMore={message.showMore}
          />
        );
      } else {
        const newMessages: string[] = generateBotMessageWithLink(
          message.message
        );
        _finalComponent = (
          <WrapperComponent
            keys={uuidv4()}
            style={{ display: includeURL(message.message) ? 'block' : 'flex' }}
          >
            {newMessages.map((element: string) => {
              if (!includeURL(element)) {
                return (
                  <React.Fragment key={uuidv4()}>{element}</React.Fragment>
                );
              } else {
                return (
                  <Link
                    className="bot-message-2link"
                    href={element}
                    key={uuidv4()}
                    {...(message.action !== undefined
                      ? {
                          onClick: (e: React.MouseEvent) => {
                            e.preventDefault();
                            if (message.action !== undefined) {
                              message.action();
                            }
                          }
                        }
                      : { target: '_blank' })}
                  >
                    {element}
                  </Link>
                );
              }
            })}
          </WrapperComponent>
        );
      }
    } else if (WrapperComponent !== null) {
      _finalComponent = (
        <WrapperComponent keys={uuidv4()} style={message?.style}>
          {message.message}
        </WrapperComponent>
      );
    }
    return _finalComponent;
  };

  const generateCustomComponent = (
    CustomComponent: ComponentType<RegularComponentTypes>,
    componentData: ChatComponent,
    index: number,
    passedProps?: {
      keys?: string;
      options?: ChatComponent['options'];
      sound?: PlayFunction;
      style?: CSSProperties;
      componentprogressBarIndexes?: object;
      visibleMessage?: boolean;
    }
  ): ReactElement => {
    return (
      <CustomComponent
        key={passedProps?.keys ?? `${componentData.type}-${index}`}
        title={componentData.title}
        disabled={componentData.disable}
        index={index}
        {...passedProps}
      />
    );
  };

  const generateMessages = (): ReactElement => {
    let lastMessage: ReactElement | undefined;
    const _messagesVisibles = deepCopy(messages);
    const retriveMessageLinkedComponent = (
      message: CustomMessage,
      index: number
    ): ReactElement => {
      const WrapperComponent = getLinkedComponent(message.from);
      return (
        <div key={uuidv4()}>
          {generateWrappedComponentByMessage(WrapperComponent, message, index)}
        </div>
      );
    };

    if (_messagesVisibles.length > 1) {
      const messagesLastIndex = _messagesVisibles.length - 1;
      lastMessage = retriveMessageLinkedComponent(
        _messagesVisibles[messagesLastIndex],
        messagesLastIndex
      );
      _messagesVisibles.pop();
    }

    const lMessages2 = _messagesVisibles.map((ms, index: number) => {
      return retriveMessageLinkedComponent(ms, index);
    });

    return (
      <TransitionGroup>
        <CustomTransition
          component={lMessages2}
          duration={messageAnimationTime}
          key={`${_messagesVisibles.length}-${messageAnimationTime}`}
          animation={'move-up'}
        />
        {lastMessage !== undefined && (
          <CustomTransition
            component={lastMessage}
            key={`${_messagesVisibles.length}`}
            animation={'fade-in'}
            styled={{ opacity: 0 }}
            transitionStyles={{}}
            delay={messageAnimationTime}
          />
        )}
      </TransitionGroup>
    );
  };

  return (
    <>
      <div id="chat-column">
        <div
          id="message-box"
          ref={messageBoxRef}
          style={{
            marginBottom: '12px'
          }}
        >
          {generateMessages()}
        </div>
        <MagicIsland addNewMessages={addNewMessages} loading={loading} />
      </div>
    </>
  );
};

export default Chat;
