import {
  CancelOutlined,
  CheckCircleOutlined,
  ErrorOutlineOutlined,
  InfoOutlined,
  WarningAmberRounded
} from '@mui/icons-material';
import { Box } from '@mui/material';
import CustomButton from 'common/button/CustomButton';
import type { NotifyBoxTypes } from 'common/interfaces/interfaces';
import { type ReactElement } from 'react';
import './NotifyBox.scss';

//  TODO: add the new palette of colors

const NotifyBox = ({
  type,
  message,
  children = undefined,
  button
}: NotifyBoxTypes): ReactElement => {
  let AlertBoxType = { icon: <InfoOutlined />, class: 'info' };
  if (type === 'error') {
    AlertBoxType = { icon: <ErrorOutlineOutlined />, class: 'error' };
  }

  if (type === 'failed') {
    AlertBoxType = { icon: <CancelOutlined />, class: 'failed' };
  }

  if (type === 'success') {
    AlertBoxType = { icon: <CheckCircleOutlined />, class: 'success' };
  }

  if (type === 'warning') {
    AlertBoxType = { icon: <WarningAmberRounded />, class: 'warning' };
  }

  return (
    <Box id="alertBox" className={AlertBoxType.class}>
      <Box sx={{ display: 'flex', alignItems: 'center', width: 'fit-content' }}>
        {AlertBoxType.icon}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {message !== undefined && (
          <Box
            style={{ marginBottom: 5 }}
            className={`${AlertBoxType.class}${
              children !== undefined ? '-title' : ''
            }`}
          >
            {message}
          </Box>
        )}
        {children !== undefined && (
          <Box className={AlertBoxType.class}>{children}</Box>
        )}
      </Box>
      {button !== undefined && (
        <Box className="button-container">
          <CustomButton
            className={`button-notification ${type}`}
            onClick={button.onClick}
          >
            {button.text}
          </CustomButton>
        </Box>
      )}
    </Box>
  );
};

export default NotifyBox;
