import { atom } from 'recoil';

export interface UserTrackingLocation {
  current: string;
  previous: string;
}

export const userTrackingLocation = atom<UserTrackingLocation>({
  key: 'userTrackingLocation',
  default: {
    current: '',
    previous: ''
  }
});

export enum USER_TRACKING_LOCATION_NAMES {
  PREDICT = 'PREDICT',
  OPTIMISE = 'OPTIMISE',
  MYSPACE = 'MYSPACE',
  WORKSPACES = 'WORKSPACES',
  UPLOAD_DATASET = 'UPLOAD_DATASET',
  UPLOAD_MODEL = 'UPLOAD_MODEL',
  FEATURE_ENGINEERING = 'FENG',
  INSIGHTS = 'INSIGHTS',
  SUMLAYER = 'SUMLAYER',
  PLAYGROUND = 'PLAYGROUND'
}

export enum USER_TRACKING_DISPLAY_ASSETS_ACTIONS {
  MODEL_SELECT = 'SELECT_MODEL',
  DATASET_SELECT = 'SELECT_DATASET',
  MERGE_ACCESS = 'MERGE_DATASET_ACCESS',
  VERSION_SELECT = 'SELECT_DATASET_VERSION'
}

export enum USER_TRACKING_DETAILS_ACTIONS {
  USE = 'USE',
  EDIT = 'EDIT',
  SAVED = 'SAVED',
  DELETE = 'DELETE',
  PREVIEW = 'PREVIEW',
  FEAT_ENG_ACCESS = 'FEATURE_ENGINEERING_ACCESS',
  CROSSTABS_ACCESS = 'CROSSTABS_ACCESS'
}

export enum USER_TRACKING_CROSSTABS_ACTIONS {
  CROSSTABS_SUCCESSFUL = 'CROSSTABS_SUCCESSFUL_OUTCOME',
  CROSSTABS_ERROR = 'CROSSTABS_ERROR'
}

export enum USER_TRACKING_MERGE_ACTIONS {
  SAVED = 'MERGE_DATASET_SAVE',
  FAILURE = 'MERGE_DATASET_SAVE_FAIL',
  SUCCESS = 'MERGE_DATASET_SAVE_SUCCESS'
}

export enum USER_TRACKING_FENG_ACTIONS {
  SAVE = 'SAVE',
  NO_SAVE = 'USE_NO_SAVE',
  RESET = 'RESET_CHANGES',
  NEW_COLUMN = 'CREATE_NEWCOL',
  GROUPING = 'NEW_GROUPING',
  DISREGARD = 'COLSET_DISREGARD',
  REVERT = 'REVERT_COL_CHANGES',
  FILTER = 'COLSET_FILTERCOLVARS',
  CHANGE_TYPE = 'COLSET_CHANGETYPE',
  CHANGE_VIEW_MODE = 'CHANGE_VIEW_MODE'
}

export enum USER_TRACKING_SUMMARY_ACTIONS {
  ORDER = 'ORDER',
  CLOSE = 'CLOSE',
  FILTER = 'FILTER',
  KEYFACT = 'KEYFACT',
  FACTORDISP = 'FACTORDISP',
  SELECT_ROW = 'SELECT_LINE',
  RESET_FILTERS = 'RESET_FILTERS',
  SINGLEPLAYGROUND = 'SINGLEPLAYGROUND'
}

export enum TRACKED_USER_ACTION_OPTIMISE_FLOW {
  SET_GOAL = 'SET_GOAL',
  OPEN_INSIGHTS = 'OPEN_INSIGHTS',
  OPEN_SUMLAYER = 'OPEN_SUMLAYER',
  DOWNLOAD_REPORT = 'DOWNLOAD_REPORT',
  SELECT_PLOT = 'SELECT_PLOT'
}

export enum TRACKED_USER_ACTION_PREDICT_FLOW {
  BULK_PREDICTIONS = 'BULK_PREDS',
  OPEN_PLAYGROUND = 'OPEN_PLAYGROUND',
  DOWNLOAD_BULK_PREDICTIONS = 'DOWNLOAD_BULK_PREDS'
}

export enum TRACKED_USER_ACTION_PLAYGROUND {
  PREDICT = 'PREDICT',
  PREDICT_ERROR = 'PREDICT_ERROR',
  EXPLORE = 'EXPLORE',
  EXPLORE_ERROR = 'EXPLORE_ERROR',
  CLOSE = 'CLOSE'
}

export enum TRACKED_USER_ACTION_TRIGGERS {
  INITIAL = 'FIRST_STEP_MENU_OPTIONS',
  CHANGE = 'OBJECTIVE_STATEMENT_MENU_OPTIONS'
}
