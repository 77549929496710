import { Chip, type ChipTypeMap, type ChipOwnProps } from '@mui/material';
import CustomIcon from 'common/CustomIcon';
import { ICON_TYPE } from 'common/interfaces/enums';
import './CustomChips.scss';

export enum CHIPS_TYPE {
  ACCENT = 'accent',
  INFO = 'info',
  NEUTRAL = 'neutral',
  SUCCESS = 'success',
  ERROR = 'error',
  WARRNING = 'warning'
}

interface customChipsProps {
  label: React.ReactNode;
  small?: boolean;
  type?: CHIPS_TYPE;
  className?: string;
  limitedLabelBy?: number;
  icon?: JSX.Element;
  disabled?: boolean;
  handleClick?: React.MouseEventHandler;
  handleDelete?: ChipOwnProps['onDelete'];
}

/**
 * CustomChips component renders a styled chip element based on predefined types.
 *
 * - It displays according to the type provided via props, using predefined styles from `CHIPS_TYPE`.
 * - If the `className` prop includes `"chip"`, the component will be displayed as a tag-like element.
 * - Supports optional icons, click handlers, and delete functionality.
 * - Additional props can be passed for further customization.
 *
 * @component
 * @param {CHIPS_TYPE} [props.type=CHIPS_TYPE.NEUTRAL] - The chip's style type (defaults to `neutral`).
 * @param {string} [props.className] - Additional class names. If `"chip"` is provided, it renders as a tag.
 * @param {number} [props.limitedLabelBy] - A character limiter for the label to display.
 * @param {JSX.Element} [props.icon] - An optional icon to display alongside the label.
 * @param {boolean} [props.disabled=false] - Whether the chip is disabled.
 * @param {React.MouseEventHandler} [props.handleClick] - Click handler function.
 * @param {ChipOwnProps['onDelete']} [props.handleDelete] - Delete handler function.
 * @param {ChipOwnProps} [props.restPops] - Additional mui chip component props for further customization.
 *
 * @returns {JSX.Element} The rendered CustomChips component.
 *
 * @example
 * * Render a chip displayed as a tag
 * <CustomChips
 *   className="chip"
 *   icon={<CustomIcon type={ICON_TYPE.USERS01} />}
 *   label={'Account Shared'}
 * />
 *
 * @example
 * * Render a chip with an accent type
 * <CustomChips
 *   type={CHIPS_TYPE.ACCENT}
 *   label={'Early access'}
 * />
 */
const CustomChips = ({
  type,
  className,
  small,
  label,
  limitedLabelBy,
  icon,
  disabled,
  handleClick,
  handleDelete,
  ...restPops
}: customChipsProps & ChipOwnProps): JSX.Element => {
  let text = label;
  if (type === undefined) {
    type = CHIPS_TYPE.NEUTRAL;
  }
  const localOptions: ChipTypeMap['props'] = {};
  if (handleDelete !== undefined) {
    localOptions.onDelete = handleDelete;
    localOptions.deleteIcon = <CustomIcon type={ICON_TYPE.CLOSE} />;
  }

  if (typeof limitedLabelBy === 'number' && typeof label === 'string') {
    text = label.toString().substring(0, limitedLabelBy);
  }

  return (
    <Chip
      className={`custom-chip ${type} ${
        className !== undefined ? className : ''
      } ${small !== undefined && small ? 'small-chip' : ''}`}
      label={text}
      disabled={disabled !== undefined ? disabled : false}
      icon={icon !== undefined ? icon : undefined}
      onClick={handleClick}
      {...localOptions}
      {...(restPops !== undefined ? { ...restPops } : {})}
    />
  );
};

export default CustomChips;
