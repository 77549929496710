import {
  Box,
  MenuItem,
  Select,
  Typography,
  type MenuProps
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import AIZWEILOGO from 'assets/imgs/aizwei-logo-black.svg';
import { modalControllerAtom } from 'atoms/atomModalController';
import {
  USER_TRACKING_LOCATION_NAMES,
  userTrackingLocation
} from 'atoms/atomUserLocation';
import { magicMessageDerived } from 'chat/atoms/atomMagicMessage';
import { type User } from 'models/User';
import { useEffect, useState, type ReactElement } from 'react';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { GAUserEvent } from 'utils/utils';
import CustomButton from 'common/button/CustomButton';
import {
  HOME_VIEW_TYPES,
  ICON_TYPE,
  MODAL_TYPES,
  NAVIGATOR_NAMES
} from 'common/interfaces/enums';
import { Cookie } from 'utils/cookie';
import CustomIcon from 'common/CustomIcon';
import { KeyboardArrowDown } from '@mui/icons-material';
import SelectScrollFetch, {
  type valueOfSelectedFetch
} from 'common/SelectScrollFetch';
import { chatActiveAtom, homeViewRenderAtom } from 'home/atoms/atomActivedChat';
import { useLocation, useNavigate } from 'react-router';
import { navbarActiveTab } from 'home/atoms/atomNavbarActiveTab';

import 'common/Common.scss';
import './Navbar.scss';

const ACCOUNT = 'Account';
const LOGOUT = 'Logout';

const marketPlaceUrl = process.env.REACT_APP_MARKETPLACE_BASE_URL;
const marketPlacePrefixUri =
  process.env.REACT_APP_MARKETPLACE_BASE_URL_PREFIX_URI;
const plaionAccountId = process.env.REACT_APP_PLAION_ACCOUNT_ID;

interface NavBarProps {
  disabled: boolean;
  user: User | null;
  setValidToken: (validToken: boolean) => void;
}

const selectMenuProps = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center'
  },
  transformOrigin: {
    vertical: 'bottom',
    horizontal: 'center'
  },
  PaperProps: {
    sx: {
      borderRadius: '16px',
      transform: 'translateY(-10px)!important'
    }
  }
};

const NavBar = ({
  disabled,
  user,
  setValidToken
}: NavBarProps): ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();

  const accountID = user?.accountId ?? user?.account?.id;
  const [modalController, setModalController] =
    useRecoilState(modalControllerAtom);
  const [chatActive, setChatActive] = useRecoilState(chatActiveAtom);
  const [userLocationVariable, setUserLocationVariable] =
    useRecoilState(userTrackingLocation);
  const [navbarActiveTabIndex, setNavbarActiveTabIndex] =
    useRecoilState(navbarActiveTab);

  const [viewRender, setViewRender] = useRecoilState(homeViewRenderAtom);
  const resetViewRender = useResetRecoilState(homeViewRenderAtom);
  const setMagicMessage = useSetRecoilState(magicMessageDerived);
  const [selecteScrollFetchValue, setSelecteScrollFetchValue] =
    useState<valueOfSelectedFetch>();

  const resetAtomModalController = useResetRecoilState(modalControllerAtom);

  const [isDisabled, setDisabled] = useState<boolean>(true);
  const [openSessionAdmin, setOpenSessionAdmin] = useState<boolean>(false);

  const userDisplayedName =
    user !== null
      ? `${user.firstName[0].toUpperCase() ?? ''}${
          user?.lastName[0].toUpperCase() ?? ''
        }`
      : '';

  useEffect(() => {
    setOpenSessionAdmin(false);
  }, [disabled]);

  useEffect(() => {
    if (selecteScrollFetchValue !== undefined && navbarActiveTabIndex === 0) {
      setSelecteScrollFetchValue(undefined);
    }
  }, [selecteScrollFetchValue, navbarActiveTabIndex]);

  useEffect(() => {
    if (
      navbarActiveTabIndex !== 0 &&
      viewRender.type === HOME_VIEW_TYPES.WIZARD
    ) {
      setNavbarActiveTabIndex(0);
    }
    if (navbarActiveTabIndex === 0 && viewRender.stored !== undefined) {
      setViewRender({ ...viewRender.stored.render });
      setUserLocationVariable(viewRender.stored.userLocationVariable);
    }
    if (
      navbarActiveTabIndex === 0 &&
      !location.pathname.includes(NAVIGATOR_NAMES.WIZARD)
    ) {
      void navigate(`/${NAVIGATOR_NAMES.WIZARD}`);
    }
  }, [viewRender, navbarActiveTabIndex]);

  const handleMySpace = (target: HOME_VIEW_TYPES, tabIndex: number): void => {
    if (tabIndex === 0 && navbarActiveTabIndex === 0) {
      return;
    }
    if (
      (navbarActiveTabIndex === 0 || navbarActiveTabIndex === 4) &&
      tabIndex !== 0
    ) {
      setViewRender({
        type: target,
        stored: { render: viewRender, userLocationVariable }
      });
      setUserLocationVariable({
        ...userLocationVariable,
        current: USER_TRACKING_LOCATION_NAMES.MYSPACE,
        previous: userLocationVariable.current
      });
    } else {
      setViewRender({ type: target, stored: viewRender.stored });
    }
    GAUserEvent(target);
    setNavbarActiveTabIndex(tabIndex);
  };

  const handleLogout = (): void => {
    if (chatActive || modalController.type !== MODAL_TYPES.START_CHAT) {
      setMagicMessage(undefined);
      Cookie.remove('token');
      setModalController({ type: MODAL_TYPES.START_CHAT });
      setChatActive(false);
      setValidToken(false);
      resetViewRender();
      resetAtomModalController();
    }
  };

  const handleSessionAdmin = (target?: string): void => {
    setOpenSessionAdmin(!openSessionAdmin);
    if (target === LOGOUT) {
      GAUserEvent('LOGOUT');
      handleLogout();
    } else if (target === ACCOUNT) {
      GAUserEvent('OPEN_ACCOUNT');
      openMarketPlace();
    }
  };

  const openMarketPlace = (): void => {
    if (marketPlaceUrl !== undefined && marketPlacePrefixUri !== undefined) {
      window.location.href = `${marketPlaceUrl}${marketPlacePrefixUri}`;
    }
  };

  return (
    <Box id="nav-bar">
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        disabled={isDisabled}
        onClick={() => {
          if (!isDisabled) {
            setDisabled(true);
            handleLogout();
          }
        }}
      >
        <img src={AIZWEILOGO} alt="AIZWEI LOGO" />
      </IconButton>
      <Box id="nav-bar__menu">
        {chatActive && user !== null ? (
          <>
            <Box className="nav-bar-directory">
              <Typography>Workspace</Typography>
              <SelectScrollFetch
                className="custom-select navbar-workspace-select"
                valueController={[
                  selecteScrollFetchValue,
                  setSelecteScrollFetchValue
                ]}
              />
            </Box>
            <Box>
              <Box className="nav-bar-sub-directory middle-nav">
                <Typography>Tools</Typography>
                <Box
                  className={`${
                    navbarActiveTabIndex === 0 ? 'navbar-selected-tab' : ''
                  } nav-bar__menu-item`}
                  onClick={() => {
                    handleMySpace(HOME_VIEW_TYPES.WIZARD, 0);
                  }}
                >
                  Wizard
                </Box>
              </Box>
              <Box className="nav-bar-sub-directory">
                <Typography>Admin</Typography>
                <Box
                  className={`${
                    navbarActiveTabIndex === 1 ? 'navbar-selected-tab' : ''
                  } nav-bar__menu-item`}
                  onClick={() => {
                    handleMySpace(HOME_VIEW_TYPES.LOAD_DATASETS, 1);
                  }}
                >
                  Datasets
                </Box>
                <Box
                  className={`${
                    navbarActiveTabIndex === 2 ? 'navbar-selected-tab' : ''
                  } nav-bar__menu-item`}
                  onClick={() => {
                    handleMySpace(HOME_VIEW_TYPES.LOAD_MODELS, 2);
                  }}
                >
                  Models
                </Box>
              </Box>
            </Box>
            {accountID === plaionAccountId ? (
              <CustomButton variant="tertiary">
                <a
                  className="ft-bold"
                  style={{ color: 'inherit', textDecoration: 'none' }}
                  href="https://dailightai.sharepoint.com/:b:/s/AIZWEI2/EVZRsfsCPD1FqjHtGCl-3t0Bz0CyjjQvginqs3AbUksCGw?e=N9ZRGN"
                  rel="noreferrer"
                  target="_blank"
                >
                  Report
                </a>
              </CustomButton>
            ) : null}
          </>
        ) : (
          <></>
        )}
      </Box>
      <Box id="nav-bar__privacy-link">
        {chatActive && user !== null ? (
          <>
            <Select
              labelId="navbar-account-label"
              className="navbar-account-select"
              value={userDisplayedName}
              renderValue={() => (
                <Box className="navbar-render-account-select">
                  <CustomIcon type={ICON_TYPE.USER1} />
                  {userDisplayedName}
                </Box>
              )}
              IconComponent={KeyboardArrowDown}
              MenuProps={selectMenuProps as MenuProps}
              onChange={({ target }) => {
                handleSessionAdmin(target.value);
              }}
            >
              <MenuItem
                className="nav-bar-account-menu-item"
                key={ACCOUNT + '0'}
                value={ACCOUNT}
              >
                {ACCOUNT}
              </MenuItem>
              <MenuItem
                className="nav-bar-account-menu-item"
                key={LOGOUT + '0'}
                value={LOGOUT}
              >
                {LOGOUT}
              </MenuItem>
            </Select>
          </>
        ) : (
          <></>
        )}
        <a target="_blank" rel="noreferrer" href="https://aizwei.com/privacy">
          Privacy policy
        </a>
      </Box>
    </Box>
  );
};

export default NavBar;
