import {
  Box,
  Menu,
  MenuItem,
  Pagination,
  TableContainer,
  Tooltip
} from '@mui/material';
import {
  previewfiltersStateAtom,
  type FilterOptions
} from 'bulkPredictions/atoms/atomPreviewfilersState';
import type {
  PaginationStatus,
  Row,
  DisplayTableData,
  ActionControllerValues,
  TdCellOptions
} from 'common/interfaces/interfaces';
import { transformFeatureStateAtom } from 'featureEngineering/atoms/atomTransformFeature';
import {
  MENU,
  SUB_MENU,
  type ModifiedVariables
} from 'featureEngineering/featureEngineeringInterface';
import {
  cleanTablePredicate,
  defineInitialStatesByFilters,
  filterColumnsByFactors
} from 'featureEngineering/utils/previewRowsUtils';
import {
  getPreviewModified,
  transformAction
} from 'featureEngineering/utils/transformationUtils';
import type { MergedDatasetValues } from 'mergeDatasets/MergeDatasets';
import {
  useEffect,
  useRef,
  useState,
  type Dispatch,
  type SetStateAction
} from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import {
  USER_TRACKING_LOCATION_NAMES,
  USER_TRACKING_SUMMARY_ACTIONS,
  userTrackingLocation
} from 'atoms/atomUserLocation';
import type { Asset } from 'bulkPredictions/components/summary-layer/SummaryLayer';
import {
  getResizeTableDataByfilters,
  updateFilterValue
} from 'bulkPredictions/utils/summaryLayerUtils';
import 'common/Common.scss';
import CustomIcon from 'common/CustomIcon';
import CustomButton from 'common/button/CustomButton';
import { HOME_VIEW_TYPES, ICON_TYPE } from 'common/interfaces/enums';
import type { Filter } from 'models/Filter';
import { GAUserEvent } from 'utils/utils';
import { homeViewRenderAtom } from 'home/atoms/atomActivedChat';
import CustomTable, {
  TableType,
  type TableRowProps
} from 'common/table/CustomTable';
import CustomTableCell, {
  type TableCellProps
} from 'common/table/CustomTableCell';
import CustomChips from 'common/customChips/CustomChips';
import './PreviewRows.scss';

export interface PreviewRowsProps {
  displayTableData: DisplayTableData;
  options?: {
    title?: string;
    style?: React.CSSProperties;
    clickAction?: (values?: ActionControllerValues) => void;
    selectedRow?: string;
    summarySelected?: string;
    pagination?: boolean;
    bulkPredictionsData?: Asset | Record<string, string>;
    playgroundReturnData?: Asset;
    predictVariable?: string;
    modelVariable?: string;
    resultsController?: [number, Dispatch<SetStateAction<number>>];
  };
  online?: boolean;
  updateFilterOptions?: string;
  previewLoadingController?: [boolean, Dispatch<SetStateAction<boolean>>];
  getTableDataRows?: (
    filterOptions: FilterOptions,
    forceUpdate: boolean
  ) => void;
  handleDuplicatedColumn?: (columnName: string) => void;
  mergeCompatibilityValues?: MergedDatasetValues;
}

const PreviewRows = ({
  displayTableData,
  options = {
    pagination: false
  },
  updateFilterOptions,
  previewLoadingController,
  mergeCompatibilityValues,
  getTableDataRows,
  handleDuplicatedColumn,
  online = false
}: PreviewRowsProps): JSX.Element => {
  const setViewRender = useSetRecoilState(homeViewRenderAtom);
  const userLocationVariable = useRecoilValue(userTrackingLocation);

  const [transformationState, setTransformState] = useRecoilState(
    transformFeatureStateAtom
  );
  const [filterOptions, setFilterOptions] = useRecoilState(
    previewfiltersStateAtom
  );
  const [, setPreviewLoading] = previewLoadingController ?? [];
  const [results, setResults] = options?.resultsController ?? [];
  const { isFilterUpdate } = getResizeTableDataByfilters(
    filterOptions,
    updateFilterOptions
  );

  const { initialDatasetRows, initialViewRows } = defineInitialStatesByFilters(
    displayTableData,
    filterOptions,
    online
  );

  const keys = filterColumnsByFactors(displayTableData, filterOptions, options);

  const pageSize = filterOptions.pageSize ?? 10;
  const actualPage = filterOptions?.page ?? 1;

  const totalPages = Math.ceil(
    (results ?? initialDatasetRows.length) / pageSize
  );

  const initialPagination = { actual: actualPage, total: totalPages };
  const [pagination, setPagination] = useState(initialPagination);
  const [displayDataRows, setDisplayDataRows] =
    useState<Array<Row | undefined>>(initialViewRows);
  const [anchorEl, setAnchorEl] = useState<Record<string, HTMLElement>>();

  const rowSelection = useRef(-1);
  const [allowPredictionOnRow, setAllowPredictionOnRow] = useState(
    rowSelection.current
  );

  useEffect(() => {
    const initialDisplayContext = JSON.stringify(initialDatasetRows);
    const lastDisplayContext = JSON.stringify(displayDataRows);
    if (
      !online &&
      initialViewRows.length === 0 &&
      displayDataRows.length !== 0
    ) {
      if (initialDisplayContext !== lastDisplayContext) {
        if (setPreviewLoading !== undefined) {
          setPreviewLoading(true);
        }
        if (isFilterUpdate && setResults !== undefined) {
          setResults(initialDatasetRows.length);
        }
        setDisplayDataRows(initialDatasetRows);
      }
    } else if (
      online &&
      getTableDataRows !== undefined &&
      setPreviewLoading !== undefined
    ) {
      if (
        updateFilterOptions !== undefined &&
        updateFilterOptions !== JSON.stringify(filterOptions)
      ) {
        if (initialDisplayContext !== lastDisplayContext) {
          setDisplayDataRows([]);
          getTableDataRows(filterOptions, true);
        }
      } else if (
        options?.pagination !== undefined &&
        options.pagination &&
        displayDataRows.length === 0
      ) {
        const startDataRange = (pagination.actual - 1) * pageSize;
        const finishDataRange = pagination.actual * pageSize;
        setDisplayDataRows(
          initialDatasetRows.slice(startDataRange, finishDataRange)
        );
      }
    } else if (
      !online &&
      initialDatasetRows.length !== 0 &&
      displayDataRows.length === 0
    ) {
      if (options.playgroundReturnData === undefined) {
        setDisplayDataRows(initialDatasetRows);
      } else if (
        initialDatasetRows.length !== results &&
        setResults !== undefined
      ) {
        setResults(initialDatasetRows.length);
      }
    }
  }, [displayTableData, filterOptions]);

  useEffect(() => {
    if (options?.pagination !== undefined && options.pagination && !online) {
      const startDataRange = (pagination.actual - 1) * pageSize;
      const finishDataRange = pagination.actual * pageSize;
      setDisplayDataRows(
        initialDatasetRows.slice(startDataRange, finishDataRange)
      );
    }
  }, [pagination]);

  const getRowClasess = (
    modifiedData: ModifiedVariables,
    mergedData: { isDuplicated: boolean; isDiscarded: boolean }
  ): string => {
    const { isDuplicated, isDiscarded } = mergedData;
    let { disregard, edited, added } = modifiedData;

    disregard = Boolean(disregard);
    edited = Boolean(edited);
    added = Boolean(added);

    if (disregard && (Boolean(edited) || added)) {
      edited = false;
      added = false;
    } else if (edited) {
      added = false;
    }

    let disregardClass = '';
    if (disregard) disregardClass = 'disregard-row';
    if (isDiscarded) disregardClass = 'merge-disregard-row';

    return `${options.clickAction !== undefined ? 'cursor-pointer' : ''}
        ${disregardClass}
        ${isDuplicated && !isDiscarded ? 'row-duplicated' : ''}
        ${edited ? 'row-edited' : ''}
        ${added ? 'row-added' : ''}`;
  };

  const getBetterClass = (key: string): TableType | undefined => {
    let headerClass;
    if (key?.startsWith('probability')) {
      headerClass = TableType.HIGHLIGHT_2;
    } else if (
      options?.predictVariable !== undefined &&
      options.predictVariable !== '' &&
      key.endsWith('_' + options.predictVariable)
    ) {
      headerClass = TableType.HIGHLIGHT_3;
    } else if (
      filterOptions?.keyFactor !== undefined &&
      filterOptions.keyFactor === key
    ) {
      headerClass = TableType.HIGHLIGHT_1;
    }
    return headerClass;
  };

  const handlePagination = (page: number): void => {
    setPagination((prevPaginate: PaginationStatus) => {
      return { ...prevPaginate, actual: page };
    });
  };

  const onCloseAnchor = (indexString: string): void => {
    setAnchorEl((prevAnchors) => {
      if (prevAnchors === undefined) return prevAnchors;
      const { [indexString]: ind, ...restAnchor } = prevAnchors;
      return restAnchor;
    });
  };

  const handleColumnDisregardingAction = (
    indexString: string,
    isDisregarding: boolean
  ): void => {
    const foundSchema = mergeCompatibilityValues?.schema.find(
      (schema) => schema.name === keys[Number(indexString)]
    );
    if (foundSchema !== undefined) {
      const newTransformation = transformAction(
        transformationState,
        keys[Number(indexString)],
        MENU.COLUMN_SETTINGS,
        SUB_MENU.DISREGARD_COLUMN,
        {
          action: 'disregard_column',
          column: keys[Number(indexString)],
          type: foundSchema.dataValue
        }
      )(isDisregarding);
      setTransformState(newTransformation);
      if (!isDisregarding && handleDuplicatedColumn !== undefined) {
        handleDuplicatedColumn(keys[Number(indexString)]);
      }
      onCloseAnchor(indexString);
    }
  };

  const generateMenuList = (
    indexString: string,
    isDisregarded: boolean
  ): JSX.Element[] => {
    if (isDisregarded) {
      return [
        <MenuItem
          className="menu-item"
          key="menu-item-merge"
          onClick={() => {
            handleColumnDisregardingAction(indexString, true);
          }}
        >
          Include column
        </MenuItem>
      ];
    }
    return [
      <MenuItem
        className="menu-item"
        key="menu-item-discard"
        onClick={() => {
          handleColumnDisregardingAction(indexString, false);
        }}
      >
        Remove column
      </MenuItem>
    ];
  };

  const renderTableColumn = (
    key: string,
    index: number,
    {
      isDiscarded,
      isDisregarded
    }: { isDiscarded: boolean; isDisregarded: boolean }
  ): JSX.Element | string => {
    if (mergeCompatibilityValues !== undefined) {
      const indexString = `${index}`;
      return (
        <Box className="merged-columns-center">
          {key}
          {mergeCompatibilityValues.selectedKeyId !== key ? (
            <Box className="options-button">
              {!isDiscarded ? (
                <>
                  <CustomButton
                    variant="secondary"
                    small={true}
                    data-cy="merged-modal-menu-list-button"
                    style={{
                      padding: '6px',
                      minWidth: 'none'
                    }}
                    icon={{
                      type: ICON_TYPE.DOTS_VERTICAL,
                      position: 'left',
                      style: {
                        width: '15px',
                        height: '15px'
                      }
                    }}
                    onClick={(event?: React.MouseEvent<HTMLButtonElement>) => {
                      if (event !== undefined) {
                        setAnchorEl({
                          ...anchorEl,
                          [indexString]: event.currentTarget
                        });
                      }
                    }}
                  />
                  <Menu
                    id="merged-basic-menu"
                    className="option-basic-menu"
                    anchorEl={anchorEl?.[indexString]}
                    open={anchorEl?.[indexString] !== undefined}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button'
                    }}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                    onClose={() => {
                      onCloseAnchor(indexString);
                    }}
                  >
                    {generateMenuList(indexString, isDisregarded)}
                  </Menu>
                </>
              ) : null}
            </Box>
          ) : (
            <CustomChips key={index} label="Key Id" />
          )}
        </Box>
      );
    }
    return key;
  };

  const tableRender = (): JSX.Element => {
    let bodySelectedIntex = -1;
    const tableHeadRows = {
      classes: 'preview-rows-table-row',
      cells: keys?.map((key: string, index: number) => {
        const renderModifyedVariables = getPreviewModified(
          transformationState,
          key
        );
        const headerClass = getBetterClass(key);
        const classes: TdCellOptions['classes'] = {};
        if (headerClass !== undefined) {
          classes.type = headerClass;
        }

        let isDuplicated = false;
        if (
          mergeCompatibilityValues !== undefined &&
          (mergeCompatibilityValues?.duplicatedColumns.includes(key) ||
            (key.startsWith('main_') &&
              mergeCompatibilityValues?.duplicatedColumns.includes(
                `${key.split('main_')[1]}`
              )))
        ) {
          isDuplicated = true;
        }
        const isDiscarded =
          mergeCompatibilityValues?.disregardedColumns.includes(key) ?? false;

        if (options.selectedRow === key) {
          bodySelectedIntex = index;
        }
        const tableHeaderClasses = `${
          bodySelectedIntex === index ? 'select' : ''
        }
          ${getRowClasess(renderModifyedVariables, {
            isDiscarded,
            isDuplicated
          })}
          ${index === 0 ? 'rows-first-column' : ''}
        `;
        classes.class = tableHeaderClasses;
        return {
          options: { classes },
          actionController: {
            action: options.clickAction,
            values: {
              value: key,
              index
            }
          },
          jsxrest: renderTableColumn(key, index, {
            isDiscarded,
            isDisregarded: Boolean(renderModifyedVariables.disregard)
          })
        };
      })
    };
    const tableRowData: TableRowProps[] = displayDataRows
      ?.filter(cleanTablePredicate)
      .map((row, parentIndex) => {
        let isSummarySelected = '';
        const hashedRow = Object.values(row).toString();
        if (
          options.summarySelected !== undefined &&
          options.summarySelected === hashedRow
        ) {
          isSummarySelected = 'select';
        }
        const cells: TableCellProps[] = keys?.map(
          (key: string, index: number) => {
            const renderModifyedVariables = getPreviewModified(
              transformationState,
              key
            );
            const classes: TdCellOptions['classes'] = {};
            const rowClass = getBetterClass(key);
            if (rowClass !== undefined) {
              classes.type = rowClass;
            }

            let isDuplicated = false;
            if (
              mergeCompatibilityValues !== undefined &&
              (mergeCompatibilityValues?.duplicatedColumns.includes(key) ||
                (key.startsWith('main_') &&
                  mergeCompatibilityValues?.duplicatedColumns.includes(
                    `${key.split('main_')[1]}`
                  )))
            ) {
              isDuplicated = true;
            }
            const isDiscarded =
              mergeCompatibilityValues?.disregardedColumns.includes(key) ??
              false;
            let renderValue = row[key] ?? '';
            if (typeof renderValue === 'string' && renderValue.length > 50) {
              renderValue = `${renderValue.slice(0, 50)}...`;
            }

            const tableBodyClasses = `
            ${getRowClasess(renderModifyedVariables, {
              isDiscarded,
              isDuplicated
            })}
            ${bodySelectedIntex === index ? 'select' : isSummarySelected}
            ${index === 0 ? 'rows-first-column' : ''}
            `;
            classes.class = tableBodyClasses;

            const regularTdData: TableCellProps = {
              index,
              tdData: renderValue,
              options: {
                classes,
                actionController: {
                  action: options.clickAction,
                  values: {
                    value: key,
                    index:
                      options.summarySelected !== undefined ? hashedRow : index
                  }
                }
              }
            };
            if (index === 0 && allowPredictionOnRow === parentIndex) {
              const rest: TableCellProps = {
                index,
                options: {
                  actionController: {
                    action: options.clickAction,
                    values: {
                      value: key,
                      index:
                        options.summarySelected !== undefined
                          ? hashedRow
                          : index
                    }
                  },
                  isTdCell: (
                    <Box className={`allowed-bulk-row ${isSummarySelected}`}>
                      <Tooltip
                        title={
                          <Box
                            sx={{ width: '102px', padding: '6px' }}
                            className="tooltip"
                          >
                            {'Inspect result in the\npredictive playground'}
                          </Box>
                        }
                        placement="top-start"
                        arrow
                      >
                        <Box
                          className="bulk-allowed-icon"
                          onClick={() => {
                            if (options.clickAction !== undefined) {
                              options.clickAction({
                                value: key,
                                index:
                                  options.summarySelected !== undefined
                                    ? hashedRow
                                    : index
                              });
                            }
                            GAUserEvent(
                              `${userLocationVariable.current}_${USER_TRACKING_SUMMARY_ACTIONS.SINGLEPLAYGROUND}`
                            );
                            setViewRender({
                              type: HOME_VIEW_TYPES.PLAYGROUND,
                              payload: {
                                selectedRowData: row,
                                returnValues: options.bulkPredictionsData,
                                filters: {
                                  ...filterOptions,
                                  page: pagination.actual
                                }
                              }
                            });
                          }}
                        >
                          <CustomIcon type={ICON_TYPE.SINGLE_PREDICTION} />
                        </Box>
                      </Tooltip>
                      <CustomTableCell
                        index={index}
                        tdData={renderValue}
                        options={regularTdData.options}
                      />
                    </Box>
                  )
                }
              };
              return rest;
            } else {
              return regularTdData;
            }
          }
        );
        let classes = 'preview-rows-table-body-row';
        if (
          userLocationVariable.current.includes(
            USER_TRACKING_LOCATION_NAMES.FEATURE_ENGINEERING
          )
        ) {
          classes += ' no-hover';
        } else if (
          userLocationVariable.current.includes(
            USER_TRACKING_LOCATION_NAMES.PREDICT
          ) &&
          isSummarySelected !== ''
        ) {
          classes += ' select no-hover';
        }
        return {
          classes,
          cells,
          actionController: {
            onMouseOver: () => {
              if (
                options.bulkPredictionsData !== undefined &&
                Object.keys(options.bulkPredictionsData).length > 0
              ) {
                rowSelection.current = parentIndex;
                setAllowPredictionOnRow(rowSelection.current);
              }
            },
            onMouseOut: () => {
              if (
                options.bulkPredictionsData !== undefined &&
                Object.keys(options.bulkPredictionsData).length > 0
              ) {
                rowSelection.current = -1;
                setAllowPredictionOnRow(rowSelection.current);
              }
            }
          }
        };
      });

    return (
      <CustomTable
        className="preview-rows-table"
        header={tableHeadRows}
        body={tableRowData}
        disableHover={options.clickAction === undefined}
      />
    );
  };

  return (
    <Box style={{ display: 'contents' }}>
      {options.title !== undefined ? (
        <h1 className="table-label">{options.title}</h1>
      ) : null}
      <TableContainer
        className="wrapp-rows-table"
        sx={{ maxHeight: options?.style?.maxHeight ?? 380 }}
      >
        {tableRender()}
      </TableContainer>
      {displayDataRows.length > 0 &&
        options?.pagination !== undefined &&
        options.pagination && (
          <div className="preview-rows-table-pagination">
            <Pagination
              onChange={(event: React.ChangeEvent<unknown>, page: number) => {
                handlePagination(page);
                // Fetch new rows on pagination change, only on online mode
                if (online) {
                  const newFilter: { filter?: Filter | undefined } = {
                    filter: updateFilterValue(filterOptions.filter)
                  };
                  const newOptions = {
                    ...filterOptions,
                    ...(newFilter.filter !== undefined ? newFilter : {}),
                    page,
                    reset: false
                  };
                  setFilterOptions(newOptions);
                }
              }}
              count={totalPages}
              // When sorting, the page is reset to 1, so we need to update the
              // pagination component to reflect the new page number
              page={pagination.actual}
              variant="outlined"
              shape="rounded"
              showFirstButton
              showLastButton
            />
          </div>
        )}
    </Box>
  );
};

export default PreviewRows;
