import { BOT_COMPONENT, BOT_MESSAGE } from 'chat/interfaces/enums';
import type {
  Blocks,
  CustomMessage,
  ReceivedMessage
} from 'chat/interfaces/messages';

export const finishedProgressBar = (
  preLastMessage: CustomMessage,
  lastMessage: CustomMessage
): void => {
  // We disable the the previous response when it was a progress bar type response and it changes for the current message which means the prediction has finished.
  if (
    preLastMessage?.from === BOT_COMPONENT.PROGRESS_BAR &&
    lastMessage?.from === BOT_MESSAGE.MESSAGE &&
    preLastMessage?.component !== undefined
  ) {
    preLastMessage.component.disable = true;
  }
};

export const compareLastReceivedResponses = (
  receivedResponse: ReceivedMessage['response'] | CustomMessage,
  lastResponse: Blocks
): ((notExtend?: boolean) => boolean) => {
  const lastMessageStorage =
    localStorage.getItem('lastMessageStorage') ?? JSON.stringify(lastResponse);
  const messageString = JSON.stringify(receivedResponse);
  let isEqualMessage = messageString === lastMessageStorage;
  return (notExtend = false) => {
    if (!notExtend) {
      if (isEqualMessage && receivedResponse instanceof ArrayBuffer) {
        const dataStorage =
          localStorage.getItem('file_correlations') ?? 'FALSE';
        isEqualMessage = !(dataStorage === 'FALSE');
      }

      if (isEqualMessage && messageString.includes('link')) {
        isEqualMessage = false;
      }
      return isEqualMessage;
    }
    return isEqualMessage;
  };
};
