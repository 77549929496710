import { type AxiosError } from 'axios';
import type { FilterOptions } from 'bulkPredictions/atoms/atomPreviewfilersState';
import { AssistantApiService } from 'chat/services/AssistantApiService';
import { HOME_VIEW_TYPES, PredictStatus } from 'common/interfaces/enums';
import dayjs from 'dayjs';
import type { PreviewRowsProps } from 'featureEngineering/previewRows/PreviewRows';
import Playground from 'playground/Playground';
import {
  ScenarioSelectors,
  type PredictResultsResponse,
  type ScenarioResult
} from 'playground/interfaces/playground';
import { useRef, type ReactElement } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { ExposeService } from 'services/ExposeService';
import { GAUserEvent, deepCopy, monthNames } from '../../utils/utils';
import {
  defaultFeatureValues,
  featureValuesAtom,
  featuresAtom,
  loadingAtom,
  loadingJobAtom,
  modelMatadataAtom,
  playgroundGoalAtom,
  plotsAtom,
  predictionAtom,
  runningButtonTextAtom,
  scenarioAtom,
  snackBarAtom,
  type FeatureValueType
} from '../atoms/atomPlayground';
import {
  TRACKED_USER_ACTION_PLAYGROUND,
  userTrackingLocation
} from 'atoms/atomUserLocation';
import { homeViewRenderAtom } from 'home/atoms/atomActivedChat';

export interface DataLayerOptions {
  selectedRowData?: Record<string, string>;
  returnValues?: PreviewRowsProps;
  filters?: FilterOptions;
}

const DataLayer = ({
  selectedRowData,
  returnValues,
  filters
}: DataLayerOptions): ReactElement => {
  const assistantApiService = AssistantApiService.getInstance();
  const exposeApiService = ExposeService.getInstance();

  const userLocationVariable = useRecoilValue(userTrackingLocation);
  const setSnackBar = useSetRecoilState(snackBarAtom);
  const setLoading = useSetRecoilState(loadingAtom);
  const setLoadingJob = useSetRecoilState(loadingJobAtom);
  const [viewRender, setViewRender] = useRecoilState(homeViewRenderAtom);
  const [modelMetadata, setModelMetadata] = useRecoilState(modelMatadataAtom);
  const [features, setFeatures] = useRecoilState(featuresAtom);
  const [featureValues, setFeatureValues] = useRecoilState(featureValuesAtom);
  const setDefaultFeatureValues = useSetRecoilState(defaultFeatureValues);
  const setPlots = useSetRecoilState(plotsAtom);
  const setPrediction = useSetRecoilState(predictionAtom);
  const setPredictButtonText = useSetRecoilState(runningButtonTextAtom);
  const setPlaygroundGoal = useSetRecoilState(playgroundGoalAtom);
  const scenarioValues = useRecoilValue(scenarioAtom);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const savedScenarios = useRef<any>({}); // TODO: type endpoint response
  const calculatedScenarios = useRef<{
    bestCase: Record<string, string>;
    worstCase: Record<string, string>;
  }>({ bestCase: {}, worstCase: {} });

  const closeModal = (): void => {
    assistantApiService.createCountDown().catch(console.error);
    setViewRender({
      type: HOME_VIEW_TYPES.WIZARD,
      stored: viewRender.stored
    });
  };

  const openError = (error: AxiosError): void => {
    setSnackBar({
      status: 'error',
      open: true,
      message: error.message
    });
    setTimeout(() => {
      setLoading(false);
      closeModal();
    }, 2500);
  };

  const fetchData = async (): Promise<void> => {
    setLoading(true);
    await assistantApiService
      .getModelMetadata()
      .then((modelMetadata) => {
        setModelMetadata(modelMetadata);
        setFeatures(modelMetadata.featureImportances);
        if (selectedRowData !== undefined) {
          //  when return to bulk data is from the playground we need to set previous Feature values
          const newFeatureValues: FeatureValueType = {};
          Object.keys(modelMetadata.featureImportances).forEach(
            (recivedInputs) => {
              if (selectedRowData[recivedInputs] !== undefined) {
                newFeatureValues[recivedInputs] =
                  selectedRowData[recivedInputs];
              }
            }
          );
          if (
            selectedRowData.date_year !== undefined &&
            selectedRowData.date_month !== undefined &&
            monthNames.indexOf(selectedRowData.date_month) !== undefined &&
            selectedRowData.date_dayofmonth !== undefined
          ) {
            newFeatureValues.date = dayjs()
              .set('day', Number(selectedRowData.date_year))
              .set('month', monthNames.indexOf(selectedRowData.date_month))
              .set('date', Number(selectedRowData.date_dayofmonth));
          }

          setFeatureValues(newFeatureValues);
        } else {
          setDefaultFeatureValues(modelMetadata.featureImportances);
        }
      })
      .catch(openError);
    await assistantApiService.getFeaturePlot().then(setPlots).catch(openError);
    setLoading(false);
  };

  const updateFeatureValues = (name: string, value: string): void => {
    const newFeatureValues = { ...featureValues };
    newFeatureValues[name] = value;
    setFeatureValues(newFeatureValues);
  };

  type savedScenarioType = Record<string, Array<Record<string, string>>>;

  const validateScenario = (savedScenario: savedScenarioType): boolean => {
    let valid = true;
    Object.keys(savedScenario).forEach((key) => {
      if (savedScenario[key].length === 2) {
        savedScenario[key].forEach((element: Record<string, string>) => {
          const values = element[Object.keys(element)[0]];
          if (values === undefined || Object.keys(values).length === 0) {
            valid = false;
          }
        });
      } else {
        valid = false;
      }
    });
    return valid;
  };

  const runPredict = async (): Promise<void> => {
    setPrediction({
      prediction: 0,
      targetColumn: '',
      isProbability: false
    });
    if (featureValues !== undefined) {
      setPlaygroundGoal({
        selector: '',
        intention: '',
        goal: '',
        update: false
      });
      setLoadingJob(true);
      setPredictButtonText('Running');
      const runningPrediction: PredictResultsResponse =
        await assistantApiService.predictResults(featureValues);

      const statusInterval: NodeJS.Timer = setInterval(() => {
        void getJobStatus({ intervalId: statusInterval, runningPrediction });
      }, 5000); //  TODO: PLAION temporal change, it will be removed when AIZ-1507 is done
    }
  };

  const runScenario = async (
    selector: string,
    intention: string,
    goal: string
  ): Promise<void> => {
    const body = {
      variant: goal,
      scenario: selector,
      goal: intention,
      target: modelMetadata.label,
      url: modelMetadata.datasetUrl,
      channel_id: assistantApiService.channelId
    };

    setPlaygroundGoal({
      selector,
      intention,
      goal,
      update: false
    });
    const uniqueScenarioIds = `${goal}-${selector}-${intention}`;
    setLoadingJob(true);
    setPredictButtonText('Running');

    const runScenarioPipeline = async (): Promise<void> => {
      const runningScenario: ScenarioResult =
        await exposeApiService.scenarioResult(body);

      const statusInterval: NodeJS.Timer = setInterval(() => {
        void getJobStatus({
          intervalId: statusInterval,
          uniqueScenarioIds,
          runningScenario,
          selector
        });
      }, 5000);
    };

    const savedScenario = await exposeApiService.getSavedScenarios(
      modelMetadata.modelId,
      body.target
    );
    if (savedScenario.name === undefined) {
      const validScenario = validateScenario(savedScenario[body.target]);
      if (!validScenario) {
        await runScenarioPipeline();
      } else {
        savedScenarios.current = savedScenario[body.target];
        updateCalculatedScenarios();

        setTimeout(() => {
          updateScenarios();
          setPlaygroundGoal((prevState) => ({
            ...prevState,
            update: true
          }));
          setLoadingJob(false);
          setSnackBar({
            status: 'success',
            open: true,
            message: 'Execution completed'
          });
          setPredictButtonText('Predict');
        }, 1000);
      }
    } else {
      await runScenarioPipeline();
    }
  };

  const catchFailedPrediction = (
    { message }: { message: string },
    isScenario: boolean
  ): void => {
    setLoadingJob(false);
    setPredictButtonText('Predict');
    GAUserEvent(
      `${userLocationVariable.current}_${
        isScenario
          ? TRACKED_USER_ACTION_PLAYGROUND.EXPLORE_ERROR
          : TRACKED_USER_ACTION_PLAYGROUND.PREDICT_ERROR
      }`
    );
    setSnackBar({
      status: 'error',
      open: true,
      message
    });
  };

  const getJobStatus = async (params: {
    intervalId: NodeJS.Timer;
    uniqueScenarioIds?: string;
    runningPrediction?: PredictResultsResponse;
    runningScenario?: ScenarioResult;
    selector?: string;
  }): Promise<void> => {
    const { intervalId, runningPrediction, runningScenario, selector } = params;
    let jobStatus = { status: PredictStatus.FAILED, error: 'Job not found' };
    if (runningPrediction !== undefined) {
      jobStatus = await exposeApiService.predictStatus(runningPrediction.jobId);
    } else if (runningScenario !== undefined) {
      jobStatus = await exposeApiService.predictStatus(runningScenario.runId);
    }
    switch (jobStatus.status) {
      case PredictStatus.RUNNING:
        setPredictButtonText('Running');
        break;
      case PredictStatus.FAILED:
        clearInterval(intervalId);
        catchFailedPrediction(
          { message: jobStatus.error },
          runningScenario !== undefined
        );
        break;
      case PredictStatus.COMPLETED:
      case PredictStatus.SUCCEEDED: //  TODO: PLAION temporal change, it will be removed when AIZ-1507 is done
        clearInterval(intervalId);
        setPredictButtonText('Completed');
        if (runningPrediction !== undefined) {
          await exposeApiService
            .predictResult(
              runningPrediction.downloadId,
              runningPrediction.inferenceUrl
            )
            .then((response) => {
              setPrediction(response);
              setLoadingJob(false);
              setSnackBar({
                status: 'success',
                open: true,
                message: 'Execution completed'
              });
              setTimeout(() => {
                setPredictButtonText('Predict');
              }, 2500);
            })
            .catch((error): void => {
              catchFailedPrediction(error, false);
            });
        } else if (runningScenario !== undefined && selector !== undefined) {
          await exposeApiService
            .getScenarioResult(runningScenario.downloadUrl)
            .then((response) => {
              setPlaygroundGoal((prevState) => ({
                ...prevState,
                update: true
              }));
              updateScenarios(true, response);
              setLoadingJob(false);
              setSnackBar({
                status: 'success',
                open: true,
                message: 'Execution completed'
              });
              setTimeout(() => {
                setPredictButtonText('Predict');
              }, 2500);
            })
            .catch((error): void => {
              catchFailedPrediction(error, true);
            });
        }
        break;
    }
  };

  const updateCalculatedScenarios = (): void => {
    const intention: string = scenarioValues.intention;
    for (const scenarioCase of ['Best_Case', 'Worst_Case']) {
      const someCase = Object.keys(savedScenarios.current).find((key) => {
        return key.includes(scenarioValues.goal) && key.includes(scenarioCase);
      });
      if (someCase !== undefined) {
        if (scenarioCase === 'Best_Case') {
          calculatedScenarios.current.bestCase = savedScenarios.current[
            someCase
          ].find((key: Record<string, unknown>) => {
            return Object.keys(key)[0] === intention;
          })[intention];
        } else {
          calculatedScenarios.current.worstCase = savedScenarios.current[
            someCase
          ].find((key: Record<string, unknown>) => {
            return Object.keys(key)[0] === intention;
          })[intention];
        }
      }
    }
  };

  const updateScenarios = (
    newPrediction?: boolean,
    newValues?: Record<string, string>
  ): void => {
    const newFeatures = deepCopy(features);

    if (
      newPrediction !== undefined &&
      newPrediction &&
      newValues !== undefined
    ) {
      Object.keys(newFeatures).forEach((key) => {
        scenarioValues.scenario === ScenarioSelectors.BEST
          ? (newFeatures[key].scenario.bestCase = newValues[key])
          : (newFeatures[key].scenario.worstCase = newValues[key]);
      });
    } else {
      // TODO: improve types so we can loop over the cases
      Object.keys(calculatedScenarios.current.bestCase).forEach((key) => {
        const current = newFeatures[key];
        if (current !== undefined) {
          current.scenario.bestCase = calculatedScenarios.current.bestCase[key];
        }
      });
      Object.keys(calculatedScenarios.current.worstCase).forEach((key) => {
        const current = newFeatures[key];
        if (current !== undefined) {
          current.scenario.worstCase =
            calculatedScenarios.current.worstCase[key];
        }
      });
    }

    setFeatures(newFeatures);
  };

  return (
    <Playground
      closeModal={closeModal}
      fetchData={fetchData}
      runPredict={runPredict}
      updateFeatureValues={updateFeatureValues}
      runScenario={runScenario}
      options={{ selectedRowData, returnValues, filters }}
    />
  );
};

export default DataLayer;
