import { atom, selector } from 'recoil';
import type { CustomMessage } from 'chat/interfaces/messages';

const exposeMagicIslandAtom = atom<boolean>({
  key: 'exposeMagicIslandAtom',
  default: false
});

const magicMessageAtom = atom<undefined | CustomMessage>({
  key: 'magicMessageAtom',
  default: undefined
});

// ALL magic related messages are messages that are not sent by the user, but by the system.
// This is a derived atom that will be used to show the magic island when a magic message is received.
// It will also be used to hide the magic island when the magic message is removed.

export const magicMessageDerived = selector<undefined | CustomMessage>({
  key: 'magicMessageDerived',
  get: ({ get }) => get(magicMessageAtom),
  set: ({ set }, newMagicMessage) => {
    set(magicMessageAtom, newMagicMessage);
    if (newMagicMessage !== undefined) {
      set(exposeMagicIslandAtom, true);
    } else {
      set(exposeMagicIslandAtom, false);
    }
  }
});

export const collapseMagicIslandDerived = selector<boolean>({
  key: 'collapseMagicIslandDerived',
  get: ({ get }) => {
    const magicMessage = get(magicMessageAtom);
    if (magicMessage === undefined) {
      return false;
    }
    return get(exposeMagicIslandAtom);
  },
  set: ({ set }, newExposeMagicIsland) => {
    set(exposeMagicIslandAtom, newExposeMagicIsland);
  }
});
