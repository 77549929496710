/* eslint-disable @typescript-eslint/no-explicit-any */ // Disabling as d3 d parameter is not typed
import React, { useEffect, useRef } from 'react';
import { BASE_COLORS } from 'themes/foundations/Colors';
import {
  select,
  scaleLinear,
  scaleBand,
  max,
  axisLeft,
  axisBottom,
  transition,
  type Selection,
  type ScaleLinear,
  type ScaleBand,
  type Axis,
  type NumberValue
} from 'd3';

type plotData = Record<string, Array<string | number>>;

interface Props {
  plotData: plotData;
  plotType: string;
  plotTitle?: string;
  size?: 'default' | 'large';
  yLabel?: string;
  xLabel?: string;
  yAxisSufix?: string;
  xAxisSufix?: string;
}

const ChartsBars: React.FC<Props> = (props) => {
  const plotType = props.plotType;
  const plotData = props.plotData[plotType];
  const svgRef = useRef<SVGSVGElement>(null);
  const svgTooltipRef = useRef<HTMLDivElement>(null);
  const padding = props.size === 'large' ? 40 : 30;
  const paddingRange = padding * 1.5;
  const radius = props.size === 'large' ? 4 : 2;
  const labelSize = props.size === 'large' ? 12 : 8;
  const barsGap = props.plotType === 'hist_plot' ? 2 : 4;
  const yAxisTicks = 3;
  let handleResize: () => void;

  const adjustAxisBottomLabelsWidth = (
    bars: Selection<SVGGElement, unknown, null, undefined>,
    axisBottomGroup: Selection<SVGGElement, unknown, null, undefined>
  ): void => {
    const barWidth: number = parseInt(bars.select('rect').attr('width'));
    axisBottomGroup.selectAll('.tick text').each(function () {
      const textElement = select(this);
      const text = textElement.text();
      const fontWidth = parseInt(textElement.style('font-size')) / 1.5;
      const availableCharacters = barWidth / Math.floor(fontWidth);

      if (barWidth > fontWidth * text.length) return;

      if (barWidth < fontWidth * 2) {
        // If the bar is too small, just show the first character
        textElement.text(`${text[0]}..`);
      } else {
        textElement.text(`${text.slice(0, availableCharacters)}..`);
      }
    });
  };

  const drawChart = (): void => {
    if (plotData === undefined) return;

    const canvas = svgRef.current;
    if (canvas === null) {
      console.error('Canvas not found');
      return;
    }

    const cW = canvas.clientWidth - padding;
    const cH = canvas.clientHeight - paddingRange;

    // Creating the svg within the canvas container
    const svg = select(canvas);

    if (props.plotTitle !== undefined) {
      // Main chart title
      svg
        .append('text')
        .attr('class', 'chart-title')
        .text(props.plotTitle)
        .attr('x', '50%')
        .attr('y', 24)
        .attr('text-anchor', 'middle');
    }

    if (props.xLabel !== undefined) {
      svg
        .append('text')
        .attr('class', 'chart-axis-label')
        .text(props.xLabel)
        .attr('x', '100%')
        .attr('y', cH + paddingRange)
        .attr('text-anchor', 'end')
        .attr('font-size', labelSize)
        .attr('font-weight', 'bold');
    }

    if (props.yLabel !== undefined) {
      const yLabelText =
        props.plotType === 'hist_plot' || props.plotType === 'bar_plot_freq'
          ? 'Frequency in dataset'
          : props.yLabel;
      svg
        .append('text')
        .attr('class', 'chart-axis-label')
        .text(yLabelText)
        .attr('x', 0)
        .attr('y', labelSize)
        .attr('font-size', labelSize)
        .attr('font-weight', 'bold');
    }

    const chart = svg
      .append('g')
      .attr('transform', `translate(${padding / 2}, ${padding / 2})`);

    // Y scales and axis
    const yScale: ScaleLinear<number, number, never> = scaleLinear()
      .domain([0, max(plotData, (d: any) => d[1])])
      .range([cH, 0]);

    const axisLeftObj = axisLeft(yScale)
      .ticks(yAxisTicks)
      .tickFormat((d) => `${d as number}${props.yAxisSufix ?? ''}`);

    const axisLeftGroup = chart
      .append('g')
      .attr('class', 'axis-left')
      .call(axisLeftObj);

    const maxAxisLeftTickWidth = (): number => {
      // Calculate the width of the widest tick label
      const nodes = axisLeftGroup.selectAll('.tick text').nodes();
      const widths = nodes.map(
        (node) => (node as SVGTextElement).getBBox().width
      );
      return Math.trunc(Math.max(...widths));
    };

    axisLeftGroup.attr('transform', `translate(${maxAxisLeftTickWidth()}, 0)`);

    // X scales and axis
    const xScale: ScaleBand<string> = scaleBand()
      .domain(plotData.map((d: any) => d[0]))
      .range([0, cW - maxAxisLeftTickWidth()]);

    const xScaleFreq: ScaleLinear<number, number, never> = scaleLinear()
      .domain([0, max(plotData, (d: any) => d[0])])
      .range([0, cW - maxAxisLeftTickWidth()]);

    const axisBottomObj = (): Axis<NumberValue> | Axis<string> => {
      if (props.plotType !== 'hist_plot') {
        return axisBottom(xScale);
      } else {
        return axisBottom(xScaleFreq).ticks(3);
      }
    };

    const axisBottomGroup = chart
      .append('g')
      .attr('class', 'axis-bottom')
      .attr('transform', `translate(${maxAxisLeftTickWidth()}, ${cH})`)
      .call(axisBottomObj());

    // Adding horizontal grid lines
    const tickValues = yScale.ticks(yAxisTicks);
    chart
      .selectAll('.grid-line')
      .data(tickValues)
      .enter()
      .append('line')
      .attr('class', 'grid-line')
      .attr('x1', maxAxisLeftTickWidth())
      .attr('x2', cW - maxAxisLeftTickWidth())
      .attr('y1', (d) => yScale(d)) // Position based on the tick value
      .attr('y2', (d) => yScale(d)) // Same y position for a horizontal line
      .attr('stroke', BASE_COLORS.BASE_40)
      .attr('stroke-width', 1)
      .attr('stroke-dasharray', '4 4');

    // Adding bars
    const bars = chart
      .append('g')
      .attr('class', 'bars')
      .attr('transform', `translate(${maxAxisLeftTickWidth()}, 0)`);

    bars
      .selectAll('rect')
      .data(plotData)
      .enter()
      .append('rect')
      .attr('class', 'bar')
      .attr('x', (d: any): any => {
        const xValue: number = xScale?.(d[0]) ?? 0; // Fallback to 0 if undefined
        return xValue + 4;
      })
      .attr('width', xScale.bandwidth() - barsGap)
      .attr('y', cH)
      .attr('height', 0)
      .attr('rx', radius);

    // animating the bars
    bars
      .selectAll('rect')
      .transition(transition().duration(1000))
      .attr('y', (d: any) => yScale(d[1]))
      .attr('height', (d: any) => cH - yScale(d[1]));

    // Adjusting the size of labels to max width of the bars
    adjustAxisBottomLabelsWidth(bars, axisBottomGroup);

    // Bars hovver effect
    let dataTooltip: Selection<HTMLSpanElement, unknown, null, undefined>;
    bars
      .selectAll('rect')
      .on('mouseover', (e, d: any) => {
        const key: string = typeof d[0] === 'string' ? d[0] : d[0].toFixed(2);
        const value: string = d[1].toFixed(0);

        dataTooltip = select(svgTooltipRef.current)
          .append('span')
          .attr('class', 'chart-tooltip__text')
          .text(`${key}: ${value}${props.yAxisSufix ?? ''}`);

        select(e.currentTarget).classed('hovered', true);
      })
      .on('mouseout', (e) => {
        dataTooltip.remove();
        select(e.currentTarget).classed('hovered', false);
      });

    // Making the width of the bars responsive when the window resizes
    handleResize = (): void => {
      const cWNew = canvas.clientWidth - padding;

      // Updating scales
      xScale.range([0, cWNew - maxAxisLeftTickWidth()]);

      // Updating bars
      bars
        .selectAll('rect')
        .attr('x', (d: any): any => {
          const xValue: number = xScale?.(d[0]) ?? 0; // Fallback to 0 if undefined
          return xValue + 4;
        })
        .attr('width', xScale.bandwidth() - barsGap);

      // Updating axis
      axisBottomGroup.call(axisBottomObj());
    };

    window.addEventListener('resize', () => {
      handleResize();
    });
  };

  useEffect(() => {
    if (svgRef.current !== null) {
      // Clearing the canvas to avoid overlapping
      select(svgRef.current).selectAll('*').remove();
    }
    drawChart();
    return () => {
      // Clearing event listener to avoid dupplication
      window.removeEventListener('resize', handleResize);
    };
  }, [props.plotData]);

  return (
    <>
      <svg
        ref={svgRef}
        className="chart-bars-canvas"
        width="100%"
        height="100%"
      />
      <div ref={svgTooltipRef} className="chart-tooltip"></div>
    </>
  );
};

export default ChartsBars;
