import { BOT_MESSAGE } from 'chat/interfaces/enums';
import type { CustomMessage } from 'chat/interfaces/messages';
import { atom } from 'recoil';
import { v4 as uuidv4 } from 'uuid';

export const chatMessageAtom = atom<CustomMessage[]>({
  key: 'chatMessageAtom',
  default: [
    {
      id: uuidv4(),
      from: BOT_MESSAGE.MESSAGE,
      visible: true,
      message: 'Hi! 👋 How can I help you today?'
    }
  ]
});
