import { useParams } from 'react-router';
import { AuthenticationService } from 'services/authentication/AuthenticationService';

const llmPrototypeUrl =
  process.env.REACT_APP_LLM_PROTOTYPE_URL ??
  'https://llm-prototype-315653733071.us-central1.run.app';

interface WorkspacesChatProps {
  assetWorkspaceId?: string;
}

const WorkspacesChat = ({
  assetWorkspaceId
}: WorkspacesChatProps): JSX.Element => {
  const authenticationService = AuthenticationService.getInstance();
  const { id } = useParams();

  const codedJson = btoa(
    encodeURIComponent(
      JSON.stringify({
        dataset_id: id ?? assetWorkspaceId,
        access_token: authenticationService.authToken
      })
    )
  );
  return (
    <iframe className="workspace" src={`${llmPrototypeUrl}/?t=${codedJson}`} />
  );
};

export default WorkspacesChat;
